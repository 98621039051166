'use strict';

const InternalEvents = require('./internal-audit-trails/index');
const Events = require('./events');

const friendlyEvents = {
    ...InternalEvents.EBINDER_INTERNAL_FRIENDLY_EVENTS,
    [Events.UNKNOWN_EMAIL_AT_CONNECTOR]: 'Email Connector received a Request From an Unknown Email',
    [Events.USER_LOGGEDIN]: 'User Signed In',
    [Events.USER_CREATED]: 'User Created',
    [Events.USER_ALIAS_UPDATED]: 'User Email Connector Alias Updated',
    [Events.LOCK_OUT]: 'User Locked Out',
    [Events.ADMIN_USER_LOCK_OUT]: 'A User Has Been Locked Out',
    [Events.USER_PROFILE_UPDATED]: 'User Profile Has Been Updated',
    [Events.USER_UPDATED_CONSENT]: 'User Updated His Profile Consent',
    [Events.USER_AGREED_TERMS_OF_USE]: 'User Agreed to Terms and Conditions',
    [Events.USER_DECLINED_TERMS_OF_USE]: 'User Declined Terms and Conditions',
    [Events.SAML_PASSWORD_CHANGED]: 'SAML User Has Requsted a Password Change',
    [Events.USER_NAME_CHANGED]: 'User Name Changed',
    [Events.USER_PROFILE_INFORMATION_CHANGED]: 'Profile Information Changed',
    [Events.USER_PROFILE_CREATED]: 'User Profile Created',
    [Events.USER_PASSWORD_CHANGED]: 'User Password Changed',

    [Events.ROLE_CREATED]: 'Role Created',
    [Events.ROLE_CREATED_VIA_WORKFLOW_API]: 'Role Created via Workflow API',
    [Events.ROLE_DELETED]: 'Role Removed',
    [Events.ROLE_DELETED_VIA_WORKFLOW_API]: 'Role Removed via Workflow API',
    [Events.ROLE_PERMISSIONS_UPDATED]: 'Role Permissions Updated',
    [Events.USER_PERMISSIONS_UPDATED]: 'User Permissions Updated',
    [Events.ALL_USER_PERMISSIONS_REMOVED]: 'All User Permissions Removed',
    [Events.ALL_USER_PERMISSIONS_REMOVED_VIA_WORKFLOW_API]: 'All User Permissions Removed via Workflow API',
    [Events.ROLE_CLONED]: 'Role Duplicated',
    [Events.ROLE_CLONED_VIA_WORKFLOW_API]: 'Role Duplicated via Workflow API',
    [Events.ROLE_RENAMED]: 'Role Renamed',
    [Events.ROLE_RENAMED_VIA_WORKFLOW_API]: 'Role Renamed via Workflow API',
    [Events.ROLE_BLINDED_STATUS_UPDATED]: 'Role Blinded/Unblinded label changed',
    [Events.ROLE_BLINDED_STATUS_UPDATED_VIA_WORKFLOW_API]: 'Role Blinded/Unblinded label changed via Workflow API',
    [Events.ROLE_ACCESS_UPDATED]: 'Role Access Updated',
    [Events.BLINDED_ROLE_ACCESS_UPDATED]: 'Blinded Role Access Updated',
    [Events.UNBLINDED_ROLE_ACCESS_UPDATED]: 'Unblinded Role Access Updated',

    [Events.TASK_REMINDER]: 'Task Reminder',
    [Events.TASK_CREATED]: 'Task Created',
    [Events.TASK_UPDATED]: 'Task Updated',
    [Events.TASK_DELETED]: 'Task Deleted',
    [Events.TASK_ASSIGNED]: 'Task Assigned',
    [Events.TASK_WITHDRAWN]: 'Task Assignee Removed',

    [Events.TEAM_CREATED]: 'Team Created',
    [Events.TEAM_CREATED_VIA_WORKFLOW_API]: 'Team Created via Workflow API',
    [Events.TEAM_UPDATED]: 'Team Updated',
    [Events.TEAM_RENAMED]: 'Team Renamed',
    [Events.TEAM_TIMEZONE_CHANGED]: 'Team Timezone Changed',
    [Events.TEAM_PRINTER_BINDER_CHANGED]: 'Team Default Printer Binder Changed',
    [Events.TEAM_USER_ADDED]: 'Teammate Added',
    [Events.TEAM_USER_REMOVED]: 'Teammate Removed',
    [Events.TEAM_USERS_ADDED_TO_ROLE]: 'Teammate(s) Added to Role',
    [Events.TEAM_USERS_ADDED_TO_ROLE_VIA_WORKFLOW_API]: 'Teammate(s) Added to Role via Workflow API',
    [Events.TEAM_USERS_ADDED_TO_BLINDED_ROLE]: 'Teammate(s) Added to Blinded Role',
    [Events.TEAM_USERS_ADDED_TO_BLINDED_ROLE_VIA_WORKFLOW_API]: 'Teammate(s) Added to Blinded Role via Workflow API',
    [Events.TEAM_USERS_ADDED_TO_UNBLINDED_ROLE]: 'Teammate(s) Added to Unblinded Role',
    [Events.TEAM_USERS_ADDED_TO_UNBLINDED_ROLE_VIA_WORKFLOW_API]: 'Teammate(s) Added to Unblinded Role via Workflow API',
    [Events.TEAM_USERS_REMOVED_FROM_ROLE]: 'Teammate(s) Removed from Role',
    [Events.TEAM_USERS_REMOVED_FROM_ROLE_VIA_WORKFLOW_API]: 'Teammate(s) Removed from Role via Workflow API',
    [Events.TEAM_USERS_REMOVED_FROM_BLINDED_ROLE]: 'Teammate(s) Removed from Blinded Role',
    [Events.TEAM_USERS_REMOVED_FROM_UNBLINDED_ROLE]: 'Teammate(s) Removed from Unblinded Role',
    [Events.TEAM_PASSWORD_POLICY_CHANGED]: 'Team Password Policy Changed',
    [Events.TEAM_SIGNATURE_POLICY_CHANGED]: 'Team Signature Policy Changed',
    [Events.TEAM_FORM_PLACEHOLDER_CHANGED]: 'Team Form Field Text Changed',
    [Events.TEAM_SIGNING_PIN_POLICY_CHANGED]: 'Team Signing PIN Policy Changed',
    [Events.TEAM_AUTOMATIC_BINDER_OWNER_SETTING_CHANGED]: 'Team Automatic Binder Owner Setting Changed',
    [Events.TEAM_AUTOMATIC_DOCUMENT_OWNER_SETTING_CHANGED]: 'Team Automatic Document Owner Setting Changed',
    [Events.TEAM_SSO_POLICY_CHANGED]: 'Team SSO Policy Changed',
    [Events.TEAM_SUPPORT_CONTACT_CHANGED]: 'Team Support Contact Changed',
    [Events.TEAM_TWO_FACTOR_AUTHENTICATION_CHANGED]: 'Team Two Factor Authentication Setting Changed',
    [Events.TEAM_TIMEOUT_PERIOD_UPDATED]: 'Team Timeout Period Updated',
    [Events.TEAM_USER_NAME_CHANGED]: 'User Name Changed In Team',
    [Events.TEAM_USER_PROFILE_CREATED]: 'User Profile Created In Team',
    [Events.TEAM_SIP_INTEGRATION_LINK_CREATED]: 'SIP Configuration Successfully Created',
    [Events.TEAM_SIP_INTEGRATION_LINK_UPDATED]: 'SIP Configuration Updated',
    [Events.TEAM_SIP_INTEGRATION_LINK_DELETED]: 'SIP Configuration Deleted',

    [Events.PAYWALL_CREATED]: 'Monitor Group Created',
    [Events.PAYWALL_UPDATED]: 'Monitor Group Updated',

    [Events.BINDER_CREATED]: 'Binder Created',
    [Events.BINDER_CREATED_VIA_WORKFLOW_API]: 'Binder Created via Workflow API',
    [Events.BINDER_DOWNLOADED]: 'Binder Downloaded',
    [Events.BINDER_RENAMED]: 'Binder Renamed',
    [Events.BINDER_RENAMED_VIA_WORKFLOW_API]: 'Binder Renamed via Workflow API',
    [Events.BINDER_DELETED]: 'Binder Deleted',
    [Events.BINDER_SIGNATURE_JOB_TITLE_ADDED]: 'Job Title Added to Signature',
    [Events.BINDER_SIGNATURE_JOB_TITLE_REMOVED]: 'Job Title Removed from Signature',
    [Events.BINDER_LABELS_UPDATED_VIA_STUDY_PROFILES]: 'Binder Labels Updated via Study Profiles',

    [Events.FOLDER_CREATED]: 'Folder Created',
    [Events.FOLDER_CREATED_VIA_WORKFLOW_API]: 'Folder Created via Workflow API',
    [Events.FOLDER_DOWNLOADED]: 'Folder Downloaded',
    [Events.FOLDER_MOVED]: 'Folder Moved',
    [Events.FOLDER_CLONED]: 'Folder Duplicated',
    [Events.FOLDER_DELETED]: 'Folder Deleted',
    [Events.FOLDER_RENAMED]: 'Folder Renamed',
    [Events.FOLDER_DOCUMENT_CREATED]: 'Document Created in Folder',
    [Events.FOLDER_DOCUMENT_DELETED]: 'Document Deleted from Folder',
    [Events.FOLDER_DOCUMENT_MOVED]: 'Document Moved from Folder',
    [Events.FOLDER_DOCUMENT_CLONED]: 'Document Duplicated from Folder',
    [Events.FOLDER_DOCUMENT_RENAMED]: 'Document Renamed in Folder',
    [Events.FOLDER_DOCUMENT_MOVED_TO]: 'Document Moved to Folder',
    [Events.FOLDER_DOCUMENT_CLONED_TO]: 'Document Duplicated to Folder',
    [Events.FOLDER_FOLDER_CREATED]: 'Sub folder Created in Folder',
    [Events.FOLDER_FOLDER_DELETED]: 'Sub folder Deleted from Folder',
    [Events.FOLDER_FOLDER_MOVED]: 'Sub folder Moved from Folder',
    [Events.FOLDER_FOLDER_CLONED]: 'Sub folder Duplicated from Folder',
    [Events.FOLDER_FOLDER_RENAMED]: 'Sub folder Renamed in Folder',
    [Events.FOLDER_FOLDER_MOVED_TO]: 'Sub folder Moved to Folder',
    [Events.FOLDER_FOLDER_CLONED_TO]: 'Sub folder Duplicated to Folder',
    [Events.FOLDER_SIGNATURE_JOB_TITLE_ADDED]: 'Job Title Added to Signature',
    [Events.FOLDER_SIGNATURE_JOB_TITLE_REMOVED]: 'Job Title Removed from Signature',
    [Events.FOLDER_LABELS_UPDATED_VIA_STUDY_PROFILES]: 'Folder Labels Updated via Study Profiles',

    [Events.DOCUMENT_DELETED]: 'Document Deleted',
    [Events.SIGNATURE_REQUEST]: 'Signature Request',
    [Events.DOCUMENT_CREATED]: 'Document Created',
    [Events.DOCUMENT_IMPORTED_VIA_EHUB]: 'Document Imported via SiteLink\u2122 Bulk Distribution',
    [Events.DOCUMENT_IMPORTED_VIA_PRINTER]: 'Document Imported Via Printer',
    [Events.DOCUMENT_IMPORTED_VIA_EXTERNAL_INTEGRATION]: 'Document Imported Via External System',
    [Events.DOCUMENT_IMPORTED_VIA_EMAIL]: 'Document Imported Via Email',
    [Events.DOCUMENT_IMPORTED_VIA_DOC_SHARING]: 'Document Imported via Document Sharing',
    [Events.DOCUMENT_SUCCESSFULLY_SHARED_VIA_DOC_SHARING]: 'Document Successfully Shared via Document Sharing',
    [Events.DOCUMENT_FAILED_FOR_SHARING_VIA_DOC_SHARING]: 'Document Failed for Sharing via Document Sharing',
    [Events.DOCUMENT_REPLACED_VIA_EMAIL]: 'Document Replaced Via Email',
    [Events.DOCUMENT_REPLACED_VIA_PRINTER]: 'Document Replaced Via Printer',
    [Events.DOCUMENT_SIGNATURE_REQUESTED]: 'Document Signature Requested',
    [Events.DOCUMENT_SIGNATURE_REQUEST_REMIND]: 'Document Signature Request Reminder',
    [Events.DOCUMENT_SIGNATURE_REQUEST_DELETED]: 'Document Signature Request Removed',
    [Events.DOCUMENT_SIGNED]: 'Document Signed',
    [Events.DOCUMENT_DECLINED]: 'Signature Request Declined',
    [Events.DOCUMENT_PLACED_SIGNATURE]: 'Signature Placed on Document',
    [Events.DOCUMENT_REDACTED]: 'Document Redacted',
    [Events.DOCUMENT_HIGHLIGHTED]: 'Document Highlighted',
    [Events.DOCUMENT_NOTATED]: 'Text Added to Document',
    [Events.DOCUMENT_STAMPED]: 'Document Stamped',
    [Events.DOCUMENT_FORM_UPDATED]: 'Form Updated',
    [Events.DOCUMENT_FORM_FINALIZED]: 'Form Finalized',
    [Events.REVISION_REQUEST]: 'Revision Requested',
    [Events.DOCUMENT_REPLACED]: 'Document Replaced',
    [Events.DOCUMENT_MOVED]: 'Document Moved',
    [Events.DOCUMENT_CLONED]: 'Document Duplicated',
    [Events.DOCUMENT_LOCKED]: 'Document Locked',
    [Events.DOCUMENT_UNLOCKED]: 'Document Unlocked',
    [Events.DOCUMENT_BULK_CLONED]: 'Document Bulk Duplicated',
    [Events.DOCUMENT_RENAMED]: 'Document Renamed',
    [Events.DOCUMENT_VIEWED]: 'Document Viewed',
    [Events.DOCUMENT_RECEIVED]: 'Document Received',
    [Events.DOCUMENT_ACCEPTED]: 'Document Accepted',
    [Events.DOCUMENT_DOWNLOADED]: 'Document Downloaded',
    [Events.DOCUMENT_DUE_DATE_SET]: 'Document Due Date Set',
    [Events.DOCUMENT_EXP_DATE_SET]: 'Document Expiration Date Set',
    [Events.EMAIL_CONNECTOR_PROCESSED_INBOUND_MESSAGE]: 'Processed Incoming Message Via Email Connector',
    [Events.DOCUMENT_REJECTED]: 'Document Rejected',
    [Events.DOCUMENT_CANCELLED]: 'Document Cancelled',
    [Events.DOCUMENT_APPROVED]: 'Document Approved',
    [Events.DOCUMENT_TAGS_UPDATED]: 'Document Tags Updated',
    [Events.DOCUMENT_CATEGORY_UPDATED]: 'Document Type Updated',
    [Events.DOCUMENT_MARKED_AS_PHI]: 'Document Marked as PHI',
    [Events.DOCUMENT_UNMARKED_AS_PHI]: 'Document Unmarked as PHI',
    [Events.DOCUMENT_SIP_STATUS_CREATED]: 'Document Metadata Update from SIP',
    [Events.DOCUMENT_EDITED_PAGES_REORDERED]: 'Document Edited - page(s) reordered',
    [Events.DOCUMENT_EDITED_PAGES_DELETED]: 'Document Edited - page(s) deleted',
    [Events.DOCUMENT_EDITED_PAGES_ROTATED]: 'Document Edited - page(s) rotated',

    [Events.PLACEHOLDER_FILLED]: 'Placeholder Filled',
    [Events.PLACEHOLDER_FILLED_VIA_EMAIL]: 'Placeholder Filled Via Email',
    [Events.PLACEHOLDER_FILLED_VIA_PRINTER]: 'Placeholder Filled Via Printer',
    [Events.PLACEHOLDER_DELETED]: 'Placeholder Deleted',
    [Events.PLACEHOLDER_CREATED]: 'Placeholder Created',
    [Events.PLACEHOLDER_MOVED]: 'Placeholder Moved',
    [Events.PLACEHOLDER_CLONED]: 'Placeholder Duplicated',
    [Events.PLACEHOLDER_BULK_CLONED]: 'Placeholder Bulk Duplicated',
    [Events.PLACEHOLDER_RENAMED]: 'Placeholder Renamed',
    [Events.PLACEHOLDER_VIEWED]: 'Placeholder Viewed',
    [Events.PLACEHOLDER_DOWNLOADED]: 'Placeholder Downloaded',
    [Events.PLACEHOLDER_DUE_DATE_SET]: 'Placeholder Due Date Set',
    [Events.PLACEHOLDER_TAGS_UPDATED]: 'Placeholder Tags Updated',

    [Events.SHORTCUT_DELETED]: 'Shortcut Deleted',
    [Events.SHORTCUT_CREATED]: 'Shortcut Created',
    [Events.SHORTCUT_CREATED_FROM]: 'Shortcut Created',
    [Events.SHORTCUT_SIGNED]: 'Shortcut Signed',
    [Events.SHORTCUT_DECLINED]: 'Signature Request Declined',
    [Events.SHORTCUT_MOVED]: 'Shortcut Moved',
    [Events.SHORTCUT_RENAMED]: 'Shortcut Renamed',
    [Events.SHORTCUT_VIEWED]: 'Shortcut Viewed',
    [Events.SHORTCUT_DOWNLOADED]: 'Shortcut Downloaded',
    [Events.SHORTCUT_DUE_DATE_SET]: 'Shortcut Due Date Set',
    [Events.SHORTCUT_EXP_DATE_SET]: 'Shortcut Expiration Date Set',
    [Events.SHORTCUT_ORIGINAL_DOC_DELETED]: 'Shortcut Original Document Deleted',
    [Events.SHORTCUT_BROKEN_PLACEHOLDER_FILLED]: 'Shortcut Broken, Placeholder Filled by Creating New Log',
    [Events.SHORTCUT_TAGS_UPDATED]: 'Shortcut Tags Updated',

    [Events.LOG_DELETED]: 'Log Deleted',
    [Events.LOG_CREATED]: 'Log Created',
    [Events.LOG_CREATED_VIA_SITELINK]: 'Log Created via SiteLink\u2122',
    [Events.LOG_MOVED]: 'Log Moved',
    [Events.LOG_RENAMED]: 'Log Renamed',
    [Events.LOG_VIEWED]: 'Log Viewed',
    [Events.LOG_DOWNLOADED]: 'Log Downloaded',
    [Events.LOG_EXP_DATE_SET]: 'Log Expiration Date Set',
    [Events.LOG_TAGS_UPDATED]: 'Log Tags Updated',
    [Events.LOG_SIGNATURE_REQUESTED]: 'Log Signature Requested',
    [Events.LOG_SIGNATURE_REQUEST_REMIND]: 'Log Signature Request Reminder',
    [Events.LOG_SIGNATURE_REQUEST_DELETED]: 'Log Signature Request Removed',
    [Events.LOG_SIGNED]: 'Log Signed',
    [Events.LOG_DECLINED]: 'Signature Request Declined',
    [Events.LOG_DETAILS_UPDATED]: 'Log Details Updated',
    [Events.LOG_NEW_VERSION_CREATED]: 'New Log Version Created',
    [Events.LOG_LEGEND_UPDATED]: 'Log Legend Updated',
    [Events.LOG_INFORMATION_UPDATED]: 'Log Information Updated',
    [Events.LOG_ROLES_RESPONSIBILITIES_UPDATED]: 'Roles and Responsibilities Updated',

    [Events.LOG_ENTRY_CREATED]: 'Log Entry Created',
    [Events.LOG_ENTRY_UPDATED]: 'Log Entry Updated',
    [Events.LOG_ENTRY_SIGNATURE_REQUESTED]: 'Log Entry Signature Requested',
    [Events.LOG_ENTRY_REQUEST_REMIND]: 'Log Entry Signature Request Reminder',
    [Events.LOG_ENTRY_REQUEST_DELETED]: 'Log Entry Signature Request Removed',
    [Events.LOG_ENTRY_SIGNED]: 'Log Entry Signed',
    [Events.LOG_ENTRY_DECLINED]: 'Log Entry Signature Request Declined',
    [Events.LOG_ENTRY_STRIKETHROUGH]: 'Log Entry Strikethrough',

    [Events.ANNOUNCEMENT_SENT]: 'Announcement Sent',
    [Events.ANNOUNCEMENT_VIEWED]: 'Announcement Viewed',

    [Events.LABEL_CREATED]: 'Label Created',
    [Events.LABEL_CREATED_VIA_STUDY_PROFILE]: 'Label Created via Study Profile',
    [Events.LABEL_UPDATED]: 'Label Updated',
    [Events.LABEL_DELETED]: 'Label Deleted',
    [Events.BINDER_LABELS_UPDATED]: 'Binder Labels Updated',
    [Events.FOLDER_LABELS_UPDATED]: 'Folder Labels Updated',

    [Events.LOG_TEMPLATE_CREATED]: 'Template Created',
    [Events.LOG_TEMPLATE_CREATED_VIA_SITELINK]: 'Template Created via SiteLink\u2122',
    [Events.LOG_TEMPLATE_UPDATED]: 'Template Updated',
    [Events.LOG_TEMPLATE_MARKED_AS_ACTIVE]: 'Template Moved To Active Templates',
    [Events.LOG_TEMPLATE_MARKED_AS_INACTIVE]: 'Template Moved To Inactive Templates',
    [Events.LOG_TEMPLATE_CLONED]: 'Template Duplicated',

    [Events.STUDY_CREATED]: 'Study Created',
    [Events.STUDY_CREATED_VIA_WORKFLOW_API]: 'Study Created via Workflow API',
    [Events.STUDY_UPDATED]: 'Study Updated',
    [Events.STUDY_UPDATED_VIA_WORKFLOW_API]: 'Study Updated via Workflow API',
    [Events.STUDY_BINDERS_AND_FOLDERS_UPDATED]: 'Study Binders & Folders Updated',
    [Events.SITE_CREATED]: 'Site Created',
    [Events.SITE_CREATED_VIA_WORKFLOW_API]: 'Site Created via Workflow API',
    [Events.SITE_UPDATED]: 'Site Updated',
    [Events.SITE_UPDATED_VIA_WORKFLOW_API]: 'Site Updated via Workflow API',
    [Events.SITE_BINDERS_AND_FOLDERS_UPDATED]: 'Site Binders & Folders Updated',
    [Events.SITE_BINDERS_AND_FOLDERS_UPDATED_VIA_WORKFLOW_API]: 'Site Binders & Folders Updated via Workflow API',
    [Events.STUDY_PAYWALLS_UPDATED]: 'Study Monitor Groups Updated',

    [Events.STUDY_TEMPLATE_IMPORTED]: 'Structure Template Imported',
    [Events.STUDY_TEMPLATE_DOWNLOADED]: 'Structure Template Downloaded',
    [Events.STUDY_TEMPLATE_DELETED]: 'Structure Template Deleted',

    [Events.QC_REVIEW_CREATED]: 'QC Review Created',
    [Events.QC_REVIEW_ACCEPTED]: 'QC Review Started',
    [Events.QC_REVIEW_DECLINED]: 'QC Review Declined',
    [Events.QC_REVIEW_APPROVED]: 'QC Review Approved',
    [Events.QC_REVIEW_REJECTED]: 'QC Review Rejected',
    [Events.QC_REVIEW_DELETED]: 'QC Review Deleted',
    [Events.QC_REVIEW_CYCLE_REMOVED]: 'QC Review Cycle Removed',
    [Events.QC_REVIEWER_CHANGED]: 'QC Reviewer Changed',
    [Events.QC_REVIEW_REJECTION_DISAGREED]: 'QC Review Findings Concluded, Determination: Disagreement',
    [Events.QC_REVIEW_REJECTION_AGREED]: 'QC Review Findings Concluded, Determination: Agreement',

    [Events.TEXT_ANNOTATION_ADDED_TO_DOCUMENT]: 'Text Annotation Added to a Document',
    [Events.TIMESTAMP_ANNOTATION_ADDED_TO_DOCUMENT]: 'Timestamp Annotation Added to a Document',

    [Events.QC_REVIEW_ADDED]: 'QC Review Cycle Added',

    [Events.TAG_CREATED]: 'Tag Created',
    [Events.TAG_DELETED]: 'Tag Deleted',
    [Events.TAG_ASSIGNED]: 'Tag Assigned',
    [Events.TAG_UPDATED]: 'Tag Updated',

    [Events.MONITOR_REVIEW_OPEN_QUERY]: 'Monitor Review - Open Query',
    [Events.MONITOR_REVIEW_CLOSED_QUERY]: 'Monitor Review - Closed Query',
    [Events.MONITOR_REVIEW_DOCUMENT_APPROVED]: 'Monitor Review - Document Approved',
    [Events.MONITOR_REVIEW_DOCUMENT_REVIEWED]: 'Monitor Review - Document Reviewed',
    [Events.MONITOR_REVIEW_COMMENT]: 'Monitor Review - Comment Added',

    [Events.DOCUMENT_INTEGRATION_RESPONSE_OK]: 'Document Successfully Sent to Integrated System',
    [Events.DOCUMENT_INTEGRATION_RESPONSE_ERROR]: 'Document Failed to Send to Integrated System',

    [Events.ARCHIVAL_SCHEDULED]: 'Long-term Archive Scheduled',
    [Events.ARCHIVAL_COMPLETED]: 'Long-term Archive Completed',
    [Events.ARCHIVE_DOWNLOADED]: 'Long-term Archive Downloaded',
    [Events.ARCHIVAL_CANCELED]: 'Long-term Archive Canceled',

    [Events.TEAM_DEACTIVATION_SCHEDULED]: 'The team has been Scheduled for Deactivation',
    [Events.TEAM_DEACTIVATED]: 'The team has been Deactivated',
    [Events.TEAM_REACTIVATED]: 'The team has been Reactivated',
    [Events.TEAM_DEACTIVATION_CANCELED]: 'The team has been Canceled for Deactivation',

    [Events.STUDY_STARTUP_STARTED]: 'Study Startup Started',
    [Events.STUDY_STARTUP_COMPLETED]: 'Study Startup Completed',
    [Events.STUDY_STARTUP_FAILED]: 'Study Startup Failed'
};


module.exports = friendlyEvents;
