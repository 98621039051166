import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as moment from 'moment';

import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';

import { CHECKBOX_STATES } from '@app/core/constants';
import { DatesUpdateTypes } from '@app/components/folders/containers/folder-show/folder-index.types';
import { CurrentSessionService } from '@app/core/current-session.service';
import { calculateEntityPath, EntityPathItem } from '@app/shared/documents/calculate-entity-path.util';
import {
    DocumentSubTypes, Document, Crumb, Binder, Folder, Team
} from '@app/shared/models';

import template from './document-bulk-set-dates.component.html';
import styles from './document-bulk-set-dates.component.scss';
import { BulkSetDatesUpdate, DocumentTableDataRow } from './document-bulk-set-dates.component.types';

@Component({
    selector: 'document-bulk-set-dates',
    template,
    styles: [String(styles)],
    providers: [{ provide: '$scope', useExisting: '$rootScope' }] // date-time-picker workaround
})
export class DocumentBulkSetDatesComponent implements OnInit {
    @Input() documents: Document[];
    @Input() parentEntity: Binder | Folder | Document;
    @Output() update = new EventEmitter<BulkSetDatesUpdate[]>();

    documentsCopy: Document[];
    crumbs: Crumb[];
    numberOfItemsToDisplay: number;
    checkboxesVisible: boolean;
    itemSize = 54;
    datesUpdateType: DatesUpdateTypes;
    entityPath: EntityPathItem[];
    date: Date | string | moment.Moment;
    updates: BulkSetDatesUpdate[] = [];
    isProcessing = false;
    bulkMode = false;
    rowsSelected = 0;
    headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
    bulkDate: Date = null;
    minDate: Date;
    maxDate: Date;
    teamTimezone: string;
    isUpdate = false;

    currentTeam: Team;

    bsConfig = {
        dateInputFormat: 'DD-MMM-YYYY',
        containerClass: 'theme-default',
        keepDatesOutOfRules: true,
        showWeekNumbers: false
    };

    constructor(
        private modalRef: BsModalRef,
        private CurrentSession: CurrentSessionService
    ) { }

    ngOnInit(): void {
        this.makeDocumentsCopy();
        this.setDatesUpdateType();
        this.setEntityPath();
        this.setTableDisplayOptions();

        this.currentTeam = this.CurrentSession.getCurrentTeam();

        this.minDate = moment().subtract(2, 'years').toDate();
        this.maxDate = moment('01/01/2100').toDate();
        this.teamTimezone = moment()
            .tz(this.currentTeam.settings.timezone)
            .format('Z');
        this.isUpdate = this.documents.length === 1
            && !!(this.documents[0].dueDate || this.documents[0].expirationDate);
    }

    cancel(): void {
        this.modalRef.hide();
    }

    setDate(value: Date, doc: Document, isBulkMode?: boolean) {
        if (doc.subType === DocumentSubTypes.shortcut) {
            return;
        }

        const { timezone } = this.currentTeam.settings;

        if (doc.subType === DocumentSubTypes.placeholder && value !== null) {
            doc.dueDate = moment.tz(value, timezone).toDate();
        }
        else if (doc.subType === DocumentSubTypes.placeholder && value === null) {
            doc.dueDate = null;
        }
        if ((doc.subType === DocumentSubTypes.content || doc.subType === DocumentSubTypes.log) && value !== null) {
            doc.expirationDate = moment.tz(value, timezone).toDate();
        }
        else if ((doc.subType === DocumentSubTypes.content || doc.subType === DocumentSubTypes.log) && value === null) {
            doc.expirationDate = null;
        }

        if (!isBulkMode) {
            this.generateUpdates();
        }
    }

    calculateDateValue(offset: 'week' | 'month' | 'year'): Date {
        const value = moment().add(
            1 as moment.DurationInputArg1,
            offset as moment.DurationInputArg2
        );

        return moment(value).toDate();
    }

    populateDate(offset: 'week' | 'month' | 'year', doc: Document): void {
        const value = this.calculateDateValue(offset);
        this.setDate(value, doc);
    }

    setBulkDate(value: Date) {
        this.bulkDate = value;

        this.documentsCopy
            .filter((row: DocumentTableDataRow) => row.checked)
            .forEach((row) => this.setDate(value, row), true);

        this.generateUpdates();
    }

    populateBulkDate(offset: 'week' | 'month' | 'year'): void {
        const value = this.calculateDateValue(offset);
        this.setBulkDate(value);
    }

    private updateRowsSelected(): void {
        this.rowsSelected = this.documentsCopy.filter((row: DocumentTableDataRow) => row.checked).length;
    }

    toggleIsCheckedAllRows(): void {
        if (
            this.headerCheckboxState === CHECKBOX_STATES.NOT_SELECTED
            || this.headerCheckboxState === CHECKBOX_STATES.PARTIALLY_SELECTED
        ) {
            this.headerCheckboxState = CHECKBOX_STATES.SELECTED;
            this.documentsCopy.forEach((row: DocumentTableDataRow) => {
                row.checked = this.headerCheckboxState;
                this.applyBulkValuesIfPresent(row);
            });
        }
        else {
            this.headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
            this.documentsCopy.forEach((row: DocumentTableDataRow) => {
                row.checked = this.headerCheckboxState;
            });
        }

        this.updateRowsSelected();
    }

    toggleIsCheckedRow(row): void {
        if (row.checked && row.checked === CHECKBOX_STATES.SELECTED) {
            row.checked = CHECKBOX_STATES.NOT_SELECTED;
        }
        else {
            row.checked = CHECKBOX_STATES.SELECTED;
        }

        this.handleHeaderCheckboxStateOnRowChange();
        this.applyBulkValuesIfPresent(row);
    }

    generateUpdates(): void {
        const documentsMap = this.documents.reduce((obj, res) => {
            obj[String(res.id)] = res;
            return obj;
        }, {});

        this.updates = this.documentsCopy.reduce((prev, doc) => {
            if (doc.subType === DocumentSubTypes.placeholder) {
                const originalDate = moment(documentsMap[String(doc.id)].dueDate || null).format('YYYY-MM-DD HH:MM:SS');
                const newDate = moment(doc.dueDate || null).format('YYYY-MM-DD HH:MM:SS');

                if (originalDate !== newDate) {
                    prev.push({ id: doc.id, subType: doc.subType, dueDate: doc.dueDate ? doc.dueDate : null });
                }
            }

            if (doc.subType === DocumentSubTypes.content || doc.subType === DocumentSubTypes.log) {
                const originalDate = moment(documentsMap[String(doc.id)].expirationDate || null).format('YYYY-MM-DD HH:MM:SS');
                const newDate = moment(doc.expirationDate || null).format('YYYY-MM-DD HH:MM:SS');
                if (originalDate !== newDate) {
                    // eslint-disable-next-line max-len
                    prev.push({ id: doc.id, subType: doc.subType, expirationDate: doc.expirationDate ? doc.expirationDate : null });
                }
            }

            return prev;
        }, []);
    }

    save(): void {
        this.update.emit(this.updates);
    }

    toggleBulkAssign(): void {
        this.bulkMode = !this.bulkMode;
    }

    private makeDocumentsCopy() {
        this.documentsCopy = _.cloneDeep(this.documents);
        this.documentsCopy = this.documentsCopy.map((doc) => ({
            ...doc,
            dueDate: doc.dueDate ? new Date(doc.dueDate) : null,
            expirationDate: doc.expirationDate ? new Date(doc.expirationDate) : null
        }));
    }

    private setTableDisplayOptions() {
        const numberOfItems = this.documentsCopy.length;
        this.numberOfItemsToDisplay = numberOfItems < 5 ? numberOfItems : 5;
        this.checkboxesVisible = numberOfItems > 1;
    }

    private setEntityPath() {
        const currentTeam = this.CurrentSession.getCurrentTeam();
        this.entityPath = calculateEntityPath(this.parentEntity, currentTeam);
        if ('documentCategory' in this.parentEntity) {
            this.entityPath.pop();
        }
    }

    private setDatesUpdateType() {
        if (this.isAnySelectedDocumentPlaceholder() && this.isAnySelectedDocumentContentOrLog()) {
            this.datesUpdateType = DatesUpdateTypes.EXPIRATION_AND_DUE;
        }

        if (this.isAnySelectedDocumentPlaceholder() && !this.isAnySelectedDocumentContentOrLog()) {
            this.datesUpdateType = DatesUpdateTypes.DUE;
        }

        if (!this.isAnySelectedDocumentPlaceholder() && this.isAnySelectedDocumentContentOrLog()) {
            this.datesUpdateType = DatesUpdateTypes.EXPIRATION;
        }
    }

    private isAnySelectedDocumentPlaceholder(): boolean {
        return this.documents
            .some((doc) => doc.subType === DocumentSubTypes.placeholder);
    }

    private isAnySelectedDocumentContentOrLog(): boolean {
        return this.documents
            .some((doc) => doc.subType === DocumentSubTypes.content || doc.subType === DocumentSubTypes.log);
    }

    private applyBulkValuesIfPresent(row): void {
        if (!this.bulkMode) {
            return;
        }

        if (this.bulkDate) {
            this.setDate(this.bulkDate, row);
        }
    }

    private handleHeaderCheckboxStateOnRowChange(): void {
        if (this.documentsCopy.every((row: DocumentTableDataRow) => row.checked)) {
            this.headerCheckboxState = CHECKBOX_STATES.SELECTED;
        }
        else if (this.documentsCopy.some((row: DocumentTableDataRow) => row.checked)) {
            this.headerCheckboxState = CHECKBOX_STATES.PARTIALLY_SELECTED;
        }
        else {
            this.headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
        }

        this.updateRowsSelected();
    }

}
