import {
    Component, Input, Output, OnChanges, EventEmitter
} from '@angular/core';

import {
    AuditTrailSubject, Document, LogEntry, LogEntryTypes, MonitorReview
} from '@app/shared/models';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { LogEntryBulkSignatureRequestsComponent } from '@app/components/signature-requests/containers/log-entry-bulk-signature-requests/log-entry-bulk-signature-requests.component';
import { ApproveReviewEvent, RejectReviewEvent } from '@app/components/qc-reviews/components/document-qc-review/document-handle-qc-review.component.types';
import { LogEntrySignatureRequestsComponent } from '@app/components/signature-requests/containers/log-entry-signature-requests/log-entry-signature-requests.component';
import { DeclineLogEntryEvent, SignLogEntriesEvent } from '../document-log-entries/document-log-entries.component.types';
import { MonitorReviewsUpdateEvent } from '../../components/monitor-review-actions/monitor-review-actions.component.types';
import { QcAcceptEvent, QcDeclineEvent } from '../../components/log-toolbar/log-toolbar.component.types';
import { LogEntryStrikethroughComponent } from '../../components/log-entry-strikethrough/log-entry-strikethrough.component';

import template from './document-log-content.component.html';
import styles from './document-log-content.component.scss';
import { StrikeThroughLogEntriesEvent } from '../../components/log-entry-strikethrough/log-entry-strikethrough.component.types';

@Component({
    selector: 'document-log-content',
    template,
    styles: [String(styles)]
})
export class DocumentLogContentComponent implements OnChanges {
    @Input() doc: Document;
    @Input() currentDisplayVersion: number;
    @Input() currentUserId: string;
    @Input() hasSignatureRequest: boolean;
    @Input() requestIsPastDue: boolean;
    @Input() monitorReviewsEnabled: boolean;
    @Input() monitorReviews: MonitorReview[];
    @Input() logEntryToUpdate: string | string[];
    @Output() requestDownload = new EventEmitter<void>();
    @Output() updateDetails = new EventEmitter<void>();
    @Output() signDocument = new EventEmitter<void>();
    @Output() signLogEntry = new EventEmitter<SignLogEntriesEvent>();
    @Output() declineDocumentSignature = new EventEmitter<void>();
    @Output() declineLogEntry = new EventEmitter<DeclineLogEntryEvent>();
    @Output() setVersion = new EventEmitter<number>();
    @Output() updateLegend = new EventEmitter<void>();
    @Output() updateInformation = new EventEmitter<void>();
    @Output() updateDoaLogRolesResponsibilities = new EventEmitter<void>();
    @Output() monitorReviewsUpdate = new EventEmitter<MonitorReviewsUpdateEvent>();
    @Output() qcCreatedOnLog = new EventEmitter<void>();
    @Output() onQcAcceptLog = new EventEmitter<QcAcceptEvent>();
    @Output() onQcDeclineLog = new EventEmitter<QcDeclineEvent>();
    @Output() onApproveQcReview = new EventEmitter<ApproveReviewEvent>();
    @Output() onRejectQcReview = new EventEmitter<RejectReviewEvent>();
    @Output() onLogEntriesStrikethrough = new EventEmitter<StrikeThroughLogEntriesEvent>();

    @Output() audit = new EventEmitter<{
        teamId: string;
        id: string;
        subject: AuditTrailSubject;
    }>();

    showManageDropdown: boolean;
    viewingFinalizedVersion: boolean;
    showSign: boolean;
    showDecline: boolean;
    showLegend = true;
    showInformation = true;
    isDocumentLocked = false;
    logEntriesLoaded = false;

    constructor(
        private Modals: ModalsService
    ) { }

    ngOnChanges(): void {
        const { permissions, isLatestVersion, logStatus } = this.doc;
        const canAssignTags = permissions.assignTags;
        const canDestroyDoc = permissions.destroyDocument;
        const canUpdateDetails = permissions.updateLogDetails;
        const canMove = isLatestVersion && permissions.moveDocument;
        const canRequestSignatures = permissions.requestSignature;
        const canSign = permissions.signDocument;
        const hasQcReview = !!this.doc.qcReview && !this.doc.isLocked;
        const canRequestQCReview = (this.doc.permissions.documentCreateEditQCReview
            || this.doc.permissions.manageQCreview
            || this.doc.permissions.qcReviewAdministrator)
                                    && this.doc.qcReview === undefined
                                    && this.canQcReviewDocBySubtypeAndStatus()
                                    && (this.doc.documentProperties?.approval?.status !== 'rejected')
                                    && !this.doc.isLocked;
        const canDownload = this.getCanDownload();

        this.showManageDropdown = canAssignTags
            || canDestroyDoc
            || canUpdateDetails
            || canMove
            || canRequestSignatures
            || canSign
            || hasQcReview
            || canRequestQCReview
            || canDownload;
        this.viewingFinalizedVersion = logStatus === 'finalized';
        this.showSign = canSign && isLatestVersion;
        this.showDecline = canSign && isLatestVersion && this.hasSignatureRequest;
        this.isDocumentLocked = this.doc.isLocked;
    }

    canQcReviewDocBySubtypeAndStatus() {

        switch (this.doc.subType) {
            case 'content':
                if (this.doc.conversionStatus === 'Complete' && (this.doc.formStatus === 'no-form' || this.doc.formStatus === 'form-finalized')) {
                    return true;
                }
                return false;
            case 'log':
                return true;
            case 'shortcut':
                return false;
            case 'placeholder':
                return false;
            default:
                return false;
        }

    }

    toggleLegend(): void {
        this.showLegend = !this.showLegend;
    }

    toggleInformation(): void {
        this.showInformation = !this.showInformation;
    }

    handleSignDocument($event): void {
        if (this.isDocumentLocked || !this.hasSignatureRequest) {
            return;
        }
        this.signDocument.emit($event);
    }

    handleSignLogEntry($event: SignLogEntriesEvent): void {
        this.signLogEntry.emit($event);
    }

    handleDeclineDocumentSignature($event): void {
        this.declineDocumentSignature.emit($event);
    }

    handleDeclineLogEntry($event: DeclineLogEntryEvent): void {
        this.declineLogEntry.emit($event);
    }

    setToVersion(event: number): void {
        this.setVersion.emit(event);
    }

    handleRequestLogEntrySignatures({ logEntries }: { logEntries: LogEntry[] }): void {
        if (logEntries.length > 1) {
            this.Modals.show(LogEntryBulkSignatureRequestsComponent, {
                class: 'modal-xl',
                initialState: {
                    logDocument: this.doc,
                    logEntries
                }
            });
            return;
        }

        const logEntry = logEntries[0];
        let preselectedSignerId = '';
        const teamMemberColumn = logEntry.columns.find((column) => column.type === LogEntryTypes.teamMember);
        if (teamMemberColumn) {
            preselectedSignerId = teamMemberColumn.value as string;
        }

        const modal = this.Modals.show(LogEntrySignatureRequestsComponent, {
            animated: false,
            keyboard: false,
            initialState: {
                logDocument: this.doc,
                logEntry,
                preselectedSignerId
            },
            class: 'modal-lg'
        });
        modal.content.dismiss.subscribe(() => modal.hide());
    }

    onStrikethroughLogEntry($event): void {
        const logEntryIds = $event.logEntries.map((logEntry) => logEntry.id.logEntryId);


        const modalRef = this.Modals.show(LogEntryStrikethroughComponent, {
            class: 'modal-md',
            initialState: {}
        });

        modalRef.content.save.subscribe((params: StrikeThroughLogEntriesEvent) => {
            params.logEntryIds = logEntryIds;
            this.onLogEntriesStrikethrough.emit(params);
        });

    }

    onQcLogCreated(): void {
        this.qcCreatedOnLog.emit();
    }

    qcAcceptLog($event: QcAcceptEvent): void {
        this.onQcAcceptLog.emit($event);
    }

    qcDeclineLog($event: QcDeclineEvent): void {
        this.onQcDeclineLog.emit($event);
    }

    onRequestDownload(): void {
        this.requestDownload.emit();
    }

    handleUpdateDetails(): void {
        this.updateDetails.emit();
    }

    handleUpdateLegend(): void {
        this.updateLegend.emit();
    }

    handleUpdateInformation(): void {
        this.updateInformation.emit();
    }

    handleUpdateDoaLogRolesResponsibilities(): void {
        this.updateDoaLogRolesResponsibilities.emit();
    }

    onMonitorReviewsUpdate($event: MonitorReviewsUpdateEvent): void {
        this.monitorReviewsUpdate.emit($event);
    }

    onLogEntriesLoaded() {
        this.logEntriesLoaded = true;
    }

    qcApproveReview($event) {
        this.onApproveQcReview.emit($event);
    }

    qcRejectReview($event) {
        this.onRejectQcReview.emit($event);
    }

    getCanDownload(): boolean {
        if (this.doc.hasPii) {
            return this.doc.isDownloadable && this.doc.permissions.downloadDocumentWithPii;
        }
        return this.doc.isDownloadable;
    }
}
