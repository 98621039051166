import { Inject, Injectable } from '@angular/core';
import { of } from 'rxjs';
import * as _ from 'lodash';
import { catchError, distinctUntilChanged, filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CurrentSessionService } from '../current-session.service';


@Injectable({
    providedIn: 'root'
})
export class UserEntersTeamAuditService {
  private readonly localStorageKey = 'teamsSession';
  private readonly endpoint = _.template('/api/teams/<%= teamId %>/session');

  constructor(
      @Inject('Window') private window: Window,
      private CurrentSession: CurrentSessionService,
      private http: HttpClient
  ) {}

  init(): void {
      this.CurrentSession.currentTeam$
          .pipe(
              filter((team) => !!team),
              distinctUntilChanged((previousTeam, currentTeam) => previousTeam.id === currentTeam.id)
          )
          .subscribe((team) => this.createTeamEnterAudit(team));
  }

  createTeamEnterAudit(team): void {
      const teamsSession = this.getItem();

      if (!team?.id) {
          return;
      }

      this.saveTeamEnterAudit(team.id);

      if (!teamsSession) {
          return this.setItem(JSON.stringify({ [team.id]: true }));
      }
      const teamsSessionObject = JSON.parse(teamsSession);
      if (!teamsSessionObject[team.id]) {
          return this.setItem(JSON.stringify({ ...teamsSessionObject, [team.id]: true }));
      }
  }

  private getItem(): string {
      return this.window.localStorage.getItem(this.localStorageKey);
  }

  private setItem(item): void {
      this.window.localStorage.setItem(this.localStorageKey, item);
  }

  private saveTeamEnterAudit(teamId: string): void {
      this.http.post(this.endpoint({ teamId }), null).pipe(
          catchError(() => {
              return of(undefined);
          })
      );
  }

}
