// Modules for this file
import * as angular from 'angular';
import angularAria from 'angular-aria';
import { upgradeModule } from '@uirouter/angular-hybrid';
import * as uiBootstrap from 'angular-ui-bootstrap';
import uiBootstrapDateTime from 'bootstrap-ui-datetime-picker';

// App modules
import annoucements from './components/annoucements';
import teams from './components/teams';
import permissions from './components/permissions';
import binders from './components/binders';
import documentSharing from './components/document-sharing';
import teamInbox from './components/team-inbox';
import downloads from './components/downloads';
import folders from './components/folders';
import documents from './components/documents';
import globalView from './components/global-view';
import helpDesk from './components/help-desk';
import { folderizer } from './components/folderizer';
import roles from './components/roles';
import auditTrail from './components/audit-trail';
import users from './components/users';
import tags from './components/tags';
import reports from './components/reports';
import notifications from './components/notifications';
import sessions from './components/sessions';
import passwordPolicy from './components/password-policy';
import { signingPinPolicyModule } from './components/signing-pin-policy';
import { signatureRequestsModule } from './components/signature-requests';
import projects from './components/projects';
import { logTemplatesModule } from './components/log-templates';
import { studiesModule } from './components/studies';
import { duplicateModule } from './components/duplicate';
import { needHelpModule } from './components/need-help';
import { archiveModule } from './components/archive';
import { studyStartupModule } from './components/study-startup';
import { StudyManagerModule } from './components/study-manager';
import connectedTeams from './components/connected-teams';
import appConfig from './shared/app-config';

import layout from './layout';
import core from './core';
import shared from './shared';
import widgets from './widgets';

const appModule = angular
    .module('fhc', [
        'ui.router',
        upgradeModule.name,
        uiBootstrap,
        uiBootstrapDateTime,
        angularAria,
        core.name,
        shared.name,
        widgets.name,
        layout.name,
        annoucements.name,
        teams.name,
        permissions.name,
        roles.name,
        binders.name,
        documentSharing.name,
        teamInbox.name,
        downloads.name,
        folders.name,
        documents.name,
        globalView.name,
        helpDesk.name,
        folderizer.name,
        auditTrail.name,
        users.name,
        tags.name,
        reports.name,
        notifications.name,
        projects.name,
        sessions.name,
        passwordPolicy.name,
        signingPinPolicyModule.name,
        signatureRequestsModule.name,
        duplicateModule.name,
        logTemplatesModule.name,
        studiesModule.name,
        needHelpModule.name,
        archiveModule.name,
        studyStartupModule.name,
        StudyManagerModule.name,
        connectedTeams.name,
        appConfig.name
    ]);

export default appModule;
