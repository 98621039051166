'use strict';

const InternalEvents = require('./internal-audit-trails/index');

module.exports = {
    ...InternalEvents.EBINDER_INTERNAL_EVENTS,
    UNKNOWN_EMAIL_AT_CONNECTOR: 'unknownEmailAtConnector',
    CONFIRM_RESET: 'confirmReset',
    UNFINISHED_PASSWORD_RESET: 'unfinishedPasswordReset',
    INVITE_TO_TEAM: 'inviteUserToTeam',
    CONFIRM_REGISTRATION: 'confirmRegistration',
    ACCOUNT_READY: 'accountReady',
    ECONSENT_NEW_USER_INVITE: 'econsentNewUserInvite',
    ECONSENT_EXISTING_USER_INVITE: 'econsentExistingUserInvite',
    SITELINK_NEW_USER_INVITE: 'sitelinkNewUserInvite',
    SITELINK_EXISTING_USER_INVITE: 'sitelinkExistingUserInvite',

    TASK_CREATED: 'taskCreated',
    TASK_REMINDER: 'taskReminder',
    TASK_UPDATED: 'taskUpdated',
    TASK_DELETED: 'taskDeleted',
    TASK_ASSIGNED: 'taskRequestAssigned',
    TASK_WITHDRAWN: 'taskRequestWithdrawn',

    TEAM_CREATED: 'teamCreated',
    TEAM_CREATED_VIA_WORKFLOW_API: 'teamCreatedViaWorkflowAPI',
    TEAM_UPDATED: 'teamUpdated',
    TEAM_RENAMED: 'teamRenamed',
    TEAM_TIMEZONE_CHANGED: 'teamTimezoneChanged',
    TEAM_PRINTER_BINDER_CHANGED: 'teamPrinterBinderChanged',
    TEAM_USER_ADDED: 'teamUserAdded',
    TEAM_USER_REMOVED: 'teamUserRemoved',
    TEAM_USERS_ADDED_TO_ROLE: 'teamUserAddedToRole',
    TEAM_USERS_ADDED_TO_ROLE_VIA_WORKFLOW_API: 'teamUserAddedToRoleViaWorkflowAPI',
    TEAM_USERS_ADDED_TO_BLINDED_ROLE: 'teamUserAddedToBlindedRole',
    TEAM_USERS_ADDED_TO_BLINDED_ROLE_VIA_WORKFLOW_API: 'teamUserAddedToBlindedRoleViaWorkflowAPI',
    TEAM_USERS_ADDED_TO_UNBLINDED_ROLE: 'teamUserAddedToUnblindedRole',
    TEAM_USERS_ADDED_TO_UNBLINDED_ROLE_VIA_WORKFLOW_API: 'teamUserAddedToUnblindedRoleViaWorkflowAPI',
    TEAM_USERS_REMOVED_FROM_ROLE: 'teamUserRemovedFromRole',
    TEAM_USERS_REMOVED_FROM_ROLE_VIA_WORKFLOW_API: 'teamUserRemovedFromRoleViaWorkflowAPI',
    TEAM_USERS_REMOVED_FROM_BLINDED_ROLE: 'teamUserRemovedFromBlindedRole',
    TEAM_USERS_REMOVED_FROM_UNBLINDED_ROLE: 'teamUserRemovedFromUnblindedRole',
    TEAM_PASSWORD_POLICY_CHANGED: 'teamPasswordPolicyChanged',
    TEAM_SIGNATURE_POLICY_CHANGED: 'teamSignaturePolicyChanged',
    TEAM_FORM_PLACEHOLDER_CHANGED: 'teamFormPlaceholderChanged',
    TEAM_SIGNING_PIN_POLICY_CHANGED: 'teamSigningPINPolicyChanged',
    TEAM_AUTOMATIC_BINDER_OWNER_SETTING_CHANGED: 'teamAutomaticBinderOwnerSettingChanged',
    TEAM_AUTOMATIC_DOCUMENT_OWNER_SETTING_CHANGED: 'teamAutomaticDocumentOwnerSettingChanged',
    TEAM_SSO_POLICY_CHANGED: 'teamSsoPolicyChanged',
    TEAM_SUPPORT_CONTACT_CHANGED: 'teamSupportContactChanged',
    TEAM_TWO_FACTOR_AUTHENTICATION_CHANGED: 'teamTwoFactorAuthenticationChanged',
    TEAM_TIMEOUT_PERIOD_UPDATED: 'teamTimeoutPeriodUpdated',
    TEAM_USER_NAME_CHANGED: 'teamUserNameChanged',
    TEAM_USER_PROFILE_CREATED: 'teamUserProfileCreated',

    TEAM_SIP_INTEGRATION_LINK_CREATED: 'teamSipIntegrationLinkCreated',
    TEAM_SIP_INTEGRATION_LINK_UPDATED: 'teamSipIntegrationLinkUpdated',
    TEAM_SIP_INTEGRATION_LINK_DELETED: 'teamSipIntegrationLinkDeleted',

    PAYWALL_CREATED: 'paywallCreated',
    PAYWALL_UPDATED: 'paywallUpdated',

    ROLE_CREATED: 'roleCreated',
    ROLE_CREATED_VIA_WORKFLOW_API: 'roleCreatedViaWorkflowAPI',
    ROLE_DELETED: 'roleDeleted',
    ROLE_DELETED_VIA_WORKFLOW_API: 'roleDeletedViaWorkflowAPI',
    ROLE_PERMISSIONS_UPDATED: 'rolePermissionsUpdate',
    USER_PERMISSIONS_UPDATED: 'userPermissionsUpdate',
    ALL_USER_PERMISSIONS_REMOVED: 'allUserPermissionsRemoved',
    ALL_USER_PERMISSIONS_REMOVED_VIA_WORKFLOW_API: 'allUserPermissionsRemovedViaWorkflowAPI',
    ROLE_RENAMED: 'roleRenamed',
    ROLE_RENAMED_VIA_WORKFLOW_API: 'roleRenamedViaWorkflowAPI',
    ROLE_BLINDED_STATUS_UPDATED: 'roleBlindedStatusUpdated',
    ROLE_BLINDED_STATUS_UPDATED_VIA_WORKFLOW_API: 'roleBlindedStatusUpdatedViaWorkflowAPI',
    ROLE_CLONED: 'roleCloned',
    ROLE_CLONED_VIA_WORKFLOW_API: 'roleClonedViaWorkflowAPI',
    ROLE_ACCESS_UPDATED: 'roleAccessUpdated',
    BLINDED_ROLE_ACCESS_UPDATED: 'blindedRoleAccessUpdated',
    UNBLINDED_ROLE_ACCESS_UPDATED: 'unblindedRoleAccessUpdated',

    USER_CREATED: 'UserCreated',
    USER_LOGGEDIN: 'UserLoggedIn',
    USER_ALIAS_UPDATED: 'UserAliasChanged',
    USER_NAME_CHANGED: 'UserNameChanged',
    USER_PROFILE_INFORMATION_CHANGED: 'UserProfileInformationChanged',
    USER_PROFILE_CREATED: 'UserProfileCreated',
    USER_PASSWORD_CHANGED: 'UserPasswordChanged',

    NEW_USER: 'newUser',
    START_SESSION: 'startSession',
    INVALID_LOGIN: 'invalidLogin',
    LOG_OUT: 'logOut',
    LOCK_OUT: 'lockOut',
    ADMIN_USER_LOCK_OUT: 'adminUserLockOut',
    USER_PROFILE_UPDATED: 'userProfileUpdated',
    USER_UPDATED_CONSENT: 'userUpdatedConsent',
    USER_AGREED_TERMS_OF_USE: 'UserAgreedTermsOfUse',
    USER_DECLINED_TERMS_OF_USE: 'UserDeclinedTermsOfUse',

    BINDER_CREATED: 'binderCreated',
    BINDER_CREATED_VIA_WORKFLOW_API: 'binderCreatedViaWorkflowAPI',
    BINDER_DOWNLOADED: 'binderDownloaded',
    BINDER_RENAMED: 'binderRenamed',
    BINDER_RENAMED_VIA_WORKFLOW_API: 'binderRenamedViaWorkflowAPI',
    BINDER_USERS_MODIFIED: 'binderUsersModified', // will be removed
    BINDER_USERS_REMOVED: 'binderUsersRemoved', // will be removed
    BINDER_DOCUMENT_DELETED: 'binderDocumentDeleted',
    BINDER_DELETED: 'binderDeleted',
    BINDER_SIGNATURE_JOB_TITLE_ADDED: 'binderSignatureJobTitleAdded',
    BINDER_SIGNATURE_JOB_TITLE_REMOVED: 'binderSignatureJobTitleRemoved',
    BINDER_LABELS_UPDATED_VIA_STUDY_PROFILES: 'binderLabelsUpdatedViaStudyProfiles',

    FOLDER_CREATED: 'folderCreated',
    FOLDER_CREATED_VIA_WORKFLOW_API: 'folderCreatedViaWorkflowAPI',
    FOLDER_DOWNLOADED: 'folderDownloaded',
    FOLDER_MOVED: 'folderMoved',
    FOLDER_CLONED: 'folderCloned',
    FOLDER_DELETED: 'folderDeleted',
    FOLDER_RENAMED: 'folderRenamed',
    FOLDER_DOCUMENT_CREATED: 'folderDocumentCreated',
    FOLDER_DOCUMENT_DELETED: 'folderDocumentDeleted',
    FOLDER_DOCUMENT_MOVED: 'folderDocumentMoved',
    FOLDER_DOCUMENT_CLONED: 'folderDocumentCloned',
    FOLDER_DOCUMENT_RENAMED: 'folderDocumentRenamed',
    FOLDER_DOCUMENT_MOVED_TO: 'folderDocumentMovedTo',
    FOLDER_DOCUMENT_CLONED_TO: 'folderDocumentClonedTo',
    FOLDER_FOLDER_CREATED: 'folderFolderCreated',
    FOLDER_FOLDER_DELETED: 'folderFolderDeleted',
    FOLDER_FOLDER_MOVED: 'folderFolderMoved',
    FOLDER_FOLDER_CLONED: 'folderFolderCloned',
    FOLDER_FOLDER_RENAMED: 'folderFolderRenamed',
    FOLDER_FOLDER_MOVED_TO: 'folderFolderMovedTo',
    FOLDER_FOLDER_CLONED_TO: 'folderFolderClonedTo',
    FOLDER_SIGNATURE_JOB_TITLE_ADDED: 'foldersSignatureJobTitleAdded',
    FOLDER_SIGNATURE_JOB_TITLE_REMOVED: 'foldersSignatureJobTitleRemoved',
    FOLDER_LABELS_UPDATED_VIA_STUDY_PROFILES: 'folderLabelsUpdatedViaStudyProfiles',

    DOCUMENT_DELETED: 'documentDeleted',
    SIGNATURE_REQUEST: 'signatureRequest',
    SIGNATURE_REQUEST_REMIND: 'signatureRequestRemind',
    DOCUMENT_CREATED: 'documentCreated',
    DOCUMENT_IMPORTED_VIA_EHUB: 'documentImportedViaEhub',
    DOCUMENT_IMPORTED_VIA_PRINTER: 'documentImportedViaPrinter',
    DOCUMENT_IMPORTED_VIA_EXTERNAL_INTEGRATION: 'documentImportedViaExternalIntegration',
    DOCUMENT_IMPORTED_VIA_EMAIL: 'documentImportedViaEmail',
    DOCUMENT_IMPORTED_VIA_DOC_SHARING: 'documentImportedViaDocSharing',
    DOCUMENT_SUCCESSFULLY_SHARED_VIA_DOC_SHARING: 'documentSuccessfullySharedViaDocSharing',
    DOCUMENT_FAILED_FOR_SHARING_VIA_DOC_SHARING: 'documentFailedSharingViaDocSharing',
    DOCUMENT_REPLACED_VIA_EMAIL: 'documentReplacedViaEmail',
    DOCUMENT_REPLACED_VIA_PRINTER: 'documentReplacedViaPrinter',
    DOCUMENT_SIGNATURE_REQUESTED: 'documentSignatureRequested',
    DOCUMENT_SIGNATURE_REQUEST_REMIND: 'documentSignatureRequestRemind',
    DOCUMENT_SIGNATURE_REQUEST_DELETED: 'documentSignatureRequestDeleted',
    DOCUMENT_SIGNED: 'documentSigned',
    DOCUMENT_DECLINED: 'documentDeclined',
    DOCUMENT_REDACTED: 'documentRedacted',
    DOCUMENT_HIGHLIGHTED: 'documentHighlighted',
    DOCUMENT_PLACED_SIGNATURE: 'documentPlacedSignature',
    DOCUMENT_NOTATED: 'documentNotated',
    DOCUMENT_STAMPED: 'documentStamped',
    DOCUMENT_FORM_UPDATED: 'documentFormUpdate',
    DOCUMENT_FORM_FINALIZED: 'documentFormFinalized',
    REVISION_REQUEST: 'documentRevisionRequest',
    DOCUMENT_REPLACED: 'documentReplaced',
    DOCUMENT_MOVED: 'documentMoved',
    DOCUMENT_LOCKED: 'documentLocked',
    DOCUMENT_UNLOCKED: 'documentUnlocked',
    DOCUMENT_CLONED: 'documentCloned',
    DOCUMENT_BULK_CLONED: 'documentBulkCloned',
    DOCUMENT_RENAMED: 'documentRenamed',
    DOCUMENT_VIEWED: 'documentViewed',
    DOCUMENT_RECEIVED: 'documentReceived',
    DOCUMENT_ACCEPTED: 'documentAccepted',
    DOCUMENT_DOWNLOADED: 'documentDownloaded',
    DOCUMENT_DUE_DATE_SET: 'documentDueDateSet',
    DOCUMENT_EXP_DATE_SET: 'documentExpDateSet',
    DOCUMENT_REJECTED: 'documentRejected',
    DOCUMENT_CANCELLED: 'documentCancelled',
    DOCUMENT_APPROVED: 'documentApproved',
    DOCUMENT_TAGS_UPDATED: 'documentTagsUpdated',
    DOCUMENT_CATEGORY_UPDATED: 'documentCategoryUpdated',
    DOCUMENT_DETAILS_UPDATED: 'documentDetailsUpdated',
    DOCUMENT_MARKED_AS_PHI: 'documentMarkedAsPhi',
    DOCUMENT_UNMARKED_AS_PHI: 'documentUnmarkedAsPhi',
    DOCUMENT_SIP_STATUS_CREATED: 'documentSIPStatusCreated',
    DOCUMENT_EDITED_PAGES_REORDERED: 'documentEditedPagesReordered',
    DOCUMENT_EDITED_PAGES_DELETED: 'documentEditedPagesDeleted',
    DOCUMENT_EDITED_PAGES_ROTATED: 'documentEditedPagesRotated',

    PLACEHOLDER_FILLED: 'placeholderFilled',
    PLACEHOLDER_FILLED_VIA_EMAIL: 'placeholderFilledViaEmail',
    PLACEHOLDER_FILLED_VIA_PRINTER: 'placeholderFilledViaPrinter',
    PLACEHOLDER_DELETED: 'placeholderDeleted',
    PLACEHOLDER_CREATED: 'placeholderCreated',
    PLACEHOLDER_MOVED: 'placeholderMoved',
    PLACEHOLDER_CLONED: 'placeholderCloned',
    PLACEHOLDER_BULK_CLONED: 'placeholderBulkCloned',
    PLACEHOLDER_RENAMED: 'placeholderRenamed',
    PLACEHOLDER_VIEWED: 'placeholderViewed',
    PLACEHOLDER_DOWNLOADED: 'placeholderDownloaded',
    PLACEHOLDER_DUE_DATE_SET: 'placeholderDueDateSet',
    PLACEHOLDER_TAGS_UPDATED: 'placeholderTagsUpdated',

    SHORTCUT_DELETED: 'shortcutDeleted',
    SHORTCUT_CREATED: 'shortcutCreated',
    SHORTCUT_CREATED_FROM: 'shortcutCreatedFrom',
    SHORTCUT_SIGNED: 'shortcutSigned',
    SHORTCUT_DECLINED: 'shortcutDeclined',
    SHORTCUT_MOVED: 'shortcutMoved',
    SHORTCUT_RENAMED: 'shortcutRenamed',
    SHORTCUT_VIEWED: 'shortcutViewed',
    SHORTCUT_DOWNLOADED: 'shortcutDownloaded',
    SHORTCUT_DUE_DATE_SET: 'shortcutDueDateSet',
    SHORTCUT_EXP_DATE_SET: 'shortcutExpDateSet',
    SHORTCUT_ORIGINAL_DOC_DELETED: 'shortcutOriginalDocDeleted',
    SHORTCUT_BROKEN_PLACEHOLDER_FILLED: 'shortcutBrokenPlaceholderFilled',
    SHORTCUT_TAGS_UPDATED: 'shortcutTagsUpdated',

    LOG_DELETED: 'logDeleted',
    LOG_CREATED: 'logCreated',
    LOG_CREATED_VIA_SITELINK: 'logCreatedViaSiteLink',
    LOG_MOVED: 'logMoved',
    LOG_RENAMED: 'logRenamed',
    LOG_VIEWED: 'logViewed',
    LOG_DOWNLOADED: 'logDownloaded',
    LOG_EXP_DATE_SET: 'logExpDateSet',
    LOG_TAGS_UPDATED: 'logTagsUpdated',
    LOG_SIGNATURE_REQUESTED: 'logSignatureRequested',
    LOG_SIGNATURE_REQUEST_REMIND: 'logSignatureRequestRemind',
    LOG_SIGNATURE_REQUEST_DELETED: 'logSignatureRequestDeleted',
    LOG_SIGNED: 'logSigned',
    LOG_DECLINED: 'logDeclined',
    LOG_DETAILS_UPDATED: 'logDetailsUpdated',
    LOG_NEW_VERSION_CREATED: 'logNewVersionCreated',
    LOG_LEGEND_UPDATED: 'logLegendUpdated',
    LOG_INFORMATION_UPDATED: 'logInformationUpdated',
    LOG_ROLES_RESPONSIBILITIES_UPDATED: 'logRolesResponsibilitiesUpdated',

    LOG_ENTRY_CREATED: 'logEntryCreated',
    LOG_ENTRY_UPDATED: 'logEntryUpdated',
    LOG_ENTRY_SIGNATURE_REQUESTED: 'logEntrySignatureRequested',
    LOG_ENTRY_REQUEST_REMIND: 'logEntryRequestRemind',
    LOG_ENTRY_REQUEST_DELETED: 'logEntryRequestDeleted',
    LOG_ENTRY_SIGNED: 'logEntrySigned',
    LOG_ENTRY_DECLINED: 'logEntryDeclined',
    LOG_ENTRY_STRIKETHROUGH: 'logEntryStruckThrough',

    TAG_CREATED: 'tagCreated',
    TAG_DELETED: 'tagDeleted',
    TAG_ASSIGNED: 'tagAssigned',
    TAG_UPDATED: 'tagUpdated',
    TAG_OBJECTS_VIEWED: 'tagObjectsViewed',

    EMAIL_CONNECTOR_PROCESSED_INBOUND_MESSAGE: 'documentsImportedEmailConnector',
    ACTION_GROUP_APPROVE_REJECT_STARTED: 'actionGroupApproveRejectStarted',
    ACTION_GROUP_APPROVE_REJECT_DOCUMENT_STATUS_CHANGED: 'actionGroupApproveRejectDocumentStatusChanged',
    ACTION_GROUP_ERROR: 'actionGroupError',

    SIGNING_PASSCODE_RESET: 'signingPasscodeReset',
    PASSWORD_CHANGED: 'passwordChanged',
    SAML_PASSWORD_CHANGED: 'samlPasswordChanged',

    ANNOUNCEMENT_SENT: 'announcementSent',
    ANNOUNCEMENT_VIEWED: 'announcementViewed',

    LABEL_CREATED: 'labelCreated',
    LABEL_CREATED_VIA_STUDY_PROFILE: 'labelCreatedViaStudy',
    LABEL_UPDATED: 'labelUpdated',
    LABEL_DELETED: 'labelDeleted',
    BINDER_LABELS_UPDATED: 'binderLabelsUpdated',
    FOLDER_LABELS_UPDATED: 'folderLabelsUpdated',

    LOG_TEMPLATE_CREATED: 'templateCreated',
    LOG_TEMPLATE_CREATED_VIA_SITELINK: 'templateCreatedViaSiteLink',
    LOG_TEMPLATE_UPDATED: 'templateUpdated',
    LOG_TEMPLATE_MARKED_AS_ACTIVE: 'templateMarkedAsActive',
    LOG_TEMPLATE_MARKED_AS_INACTIVE: 'templateMarkedAsInactive',
    LOG_TEMPLATE_CLONED: 'templateCloned',

    STUDY_CREATED: 'studyCreated',
    STUDY_CREATED_VIA_WORKFLOW_API: 'studyCreatedViaWorkflowAPI',
    STUDY_UPDATED: 'studyUpdated',
    STUDY_UPDATED_VIA_WORKFLOW_API: 'studyUpdatedViaWorkflowAPI',
    STUDY_BINDERS_AND_FOLDERS_UPDATED: 'studyBindersAndFoldersUpdated',
    SITE_CREATED: 'siteCreated',
    SITE_CREATED_VIA_WORKFLOW_API: 'siteCreatedViaWorkflowAPI',
    SITE_UPDATED: 'siteUpdated',
    SITE_UPDATED_VIA_WORKFLOW_API: 'siteUpdatedViaWorkflowAPI',
    SITE_BINDERS_AND_FOLDERS_UPDATED: 'siteBindersAndFoldersUpdated',
    SITE_BINDERS_AND_FOLDERS_UPDATED_VIA_WORKFLOW_API: 'siteBindersAndFoldersUpdatedViaWorkflowAPI',
    STUDY_PAYWALLS_UPDATED: 'studyPaywallsUpdated',

    STUDY_TEMPLATE_IMPORTED: 'structureTemplateImported',
    STUDY_TEMPLATE_DOWNLOADED: 'structureTemplateDownloaded',
    STUDY_TEMPLATE_DELETED: 'structureTemplateDeleted',

    QC_REVIEW_CREATED: 'qcReviewCreated',
    QC_REVIEW_ACCEPTED: 'qcReviewAccepted',
    QC_REVIEW_DECLINED: 'qcReviewDeclined',
    QC_REVIEW_APPROVED: 'qcReviewApproved',
    QC_REVIEW_REJECTED: 'qcReviewRejected',
    QC_REVIEW_DELETED: 'qcReviewDeleted',
    QC_REVIEW_CYCLE_REMOVED: 'qcReviewCycleRemoved',
    QC_REVIEW_ADDED: 'qcReviewAdded',
    QC_REVIEWER_CHANGED: 'qcReviewerChanged',
    QC_REVIEW_REJECTION_DISAGREED: 'qcReviewRejectionDisagreed',
    QC_REVIEW_REJECTION_AGREED: 'qcReviewRejectionAgreed',

    TEXT_ANNOTATION_ADDED_TO_DOCUMENT: 'textAnnotationAddedToDocument',
    TIMESTAMP_ANNOTATION_ADDED_TO_DOCUMENT: 'timestampAnnotationAddedToDocument',

    MONITOR_REVIEW_OPEN_QUERY: 'monitorReviewOpenQuery',
    MONITOR_REVIEW_CLOSED_QUERY: 'monitorReviewClosedQuery',
    MONITOR_REVIEW_DOCUMENT_APPROVED: 'monitorReviewDocumentApproved',
    MONITOR_REVIEW_DOCUMENT_REVIEWED: 'monitorReviewDocumentReviewed',
    MONITOR_REVIEW_COMMENT: 'monitorReviewComment',

    DOCUMENT_INTEGRATION_RESPONSE_OK: 'documentIntegrationResponseOk',
    DOCUMENT_INTEGRATION_RESPONSE_ERROR: 'documentIntegrationResponseError',

    ARCHIVAL_SCHEDULED: 'archivalScheduled',
    ARCHIVAL_COMPLETED: 'archivalCompleted',
    ARCHIVE_DOWNLOADED: 'archiveDownloaded',
    ARCHIVAL_CANCELED: 'archivalCanceled',

    TEAM_DEACTIVATION_SCHEDULED: 'teamDeactivationScheduled',
    TEAM_DEACTIVATED: 'teamDeactivated',
    TEAM_REACTIVATED: 'teamReactivated',
    TEAM_DEACTIVATION_CANCELED: 'teamDeactivationCanceled',
    TEAM_DEACTIVATION_7_DAYS_PRIOR_TO_DEACTIVATION: 'teamDeactivation7DaysPriorToDeactivation',
    SUPPORT_USER_JOB_COMPLETED: 'supportUserJobCompleted',
    STUDY_STARTUP_STARTED: 'studyStartupStarted',
    STUDY_STARTUP_COMPLETED: 'studyStartupCompleted',
    STUDY_STARTUP_FAILED: 'studyStartupFailed'
};
