import * as angular from 'angular';

import { downgradeComponent } from '@angular/upgrade/static';

import projectsRoute from './projects.route';

import { ProjectsPageComponent } from './containers/projects-page/projects-page.component';
import { ProjectsDetailPageComponent } from './containers/projects-detail-page/projects-detail-page.component';
import { DashboardTimelinesComponent } from './containers/dashboard-timelines/dashboard-timelines.component';
import { TimelineUpdateComponent } from './containers/timeline-update/timeline-update.component';
import { ProjectizerComponent } from './containers/projectizer/projectizer.component';

import { CreateTimelineModalComponent } from './compontents/create-timeline-modal/create-timeline-modal.component';
import { DestroyItemComponent } from './compontents/destroy-item/destroy-item.component';
import { DestroyProjectOrTimelineComponent } from './compontents/destroy-project/destroy-project-or-timeline.component';
import { EditProjectModalComponent } from './compontents/edit-project-modal/edit-project-modal.component';
import { TimelineFormComponent } from './compontents/timeline-form/timeline-form.component';
import { ModifyCompletionModalComponent } from './compontents/modify-completion-modal/modify-completion-modal.component';
import { TimelineMetricsComponent } from './compontents/timeline-metrics/timeline-metrics.component';

import { TimelineVisibilitySettingsComponent } from './compontents/timeline-visibility-settings/timeline-visibility-settings.component';
import { ProjectizerItemComponent } from './containers/projectizer/projectizer-item/projectizer-item.component';
import { TimelineSelectionDropdownComponent } from './compontents/timeline-selection-dropdown/timeline-selection-dropdown.component';
import { TimelineDetailPageComponent } from './containers/timeline-detail-page/timeline-detail-page.component';
import { DashboardTimelineDetailPageComponent } from './containers/dashboard-timeline-detail-page/dashboard-timeline-detail-page.component';

const dependencies = [
];

const projects = angular
    .module('app.projects', dependencies)
    .config(projectsRoute)
    .directive('dashboardTimelineDetailPage', downgradeComponent({ component: DashboardTimelineDetailPageComponent }) as ng.IDirectiveFactory)
    .directive('projectsPage', downgradeComponent({ component: ProjectsPageComponent }) as ng.IDirectiveFactory)
    .directive('projectsDetailPage', downgradeComponent({ component: ProjectsDetailPageComponent }) as ng.IDirectiveFactory)
    .directive('dashboardTimelines', downgradeComponent({ component: DashboardTimelinesComponent }) as ng.IDirectiveFactory)
    .directive('timelineDetailPage', downgradeComponent({ component: TimelineDetailPageComponent }) as ng.IDirectiveFactory)
    .directive('timelineUpdate', downgradeComponent({ component: TimelineUpdateComponent }) as ng.IDirectiveFactory)
    .directive('projectizer', downgradeComponent({ component: ProjectizerComponent }) as ng.IDirectiveFactory)
    .directive('createTimelineModal', downgradeComponent({ component: CreateTimelineModalComponent }) as ng.IDirectiveFactory)
    .directive('projectizerItem', downgradeComponent({ component: ProjectizerItemComponent }) as ng.IDirectiveFactory)
    .directive('destroyItem', downgradeComponent({ component: DestroyItemComponent }) as ng.IDirectiveFactory)
    .directive('destroyProjectOrTimeline', downgradeComponent({ component: DestroyProjectOrTimelineComponent }) as ng.IDirectiveFactory)
    .directive('editProjectModal', downgradeComponent({ component: EditProjectModalComponent }) as ng.IDirectiveFactory)
    .directive('timelineForm', downgradeComponent({ component: TimelineFormComponent }) as ng.IDirectiveFactory)
    .directive('modifyCompletionModal', downgradeComponent({ component: ModifyCompletionModalComponent }) as ng.IDirectiveFactory)
    .directive('timelineMetrics', downgradeComponent({ component: TimelineMetricsComponent }) as ng.IDirectiveFactory)
    .directive('timelineSelectionDropdown', downgradeComponent({ component: TimelineSelectionDropdownComponent }) as ng.IDirectiveFactory)
    .directive('timelineVisibilitySettings', downgradeComponent({ component: TimelineVisibilitySettingsComponent }) as ng.IDirectiveFactory);

export default projects;
