import * as angular from 'angular';

import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import { ScrollableBoxComponent } from './scrollable-box/scrollable-box.component';
import { TimezonePickerComponent } from './timezone-picker/timezone-picker.component';
import { TableFilterComponent } from './table-filter/table-filter.component';
import { PageWarningComponent } from './page-warning/page-warning.component';
import { PageInfoComponent } from './page-info/page-info.component';
import { WarningWellComponent } from './warning-well/warning-well.component';
import { CheckboxTristateComponent } from './checkbox-tristate/checkbox-tristate.component';
import { PwdAndConfirmationInputsComponent } from './pwd-and-confirmation-inputs/pwd-and-confirmation-inputs.component';
import { ToggleComponent } from './toggle/toggle.component';
import { DateTimeComponent } from './date-time/date-time.component';
import { UploadDropzoneComponent } from './upload/upload-dropzone/upload-dropzone.component';
import { UploadLinkComponent } from './upload/upload-link/upload-link.component';
import { DynamicFilterComponent } from './dynamic-filter/dynamic-filter.component';
import { ActionBarItemComponent } from './action-bar-item/action-bar-item.component';
import { NameEmailComponent } from './name-email/name-email.component';
import { BannerComponent } from './banner/banner.component';
import { FilteredSelectComponent } from './filtered-select/filtered-select.component';
import { FilterableListComponent } from './filterable-list/filterable-list.component';
import { SearchDropdownComponent } from './search-dropdown/search-dropdown.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { VirtualTreeComponent } from './virtual-tree/virtual-tree.component';
import { GenericTableComponent } from './tables/generic-table/generic-table.component';
import { GlobalSearchService } from './global-search/global-search.service';

import asyncFilter from './async-filter';
import breadcrumbs from './breadcrumbs';
import { ConfirmDestroyComponent } from './confirm-destroy/confirm-destroy.component';
import { ReasonModalComponent } from './reason/reason-modal.component';
import dateTime from './date-time-picker';
import entity from './entity';
import icons from './icons';
import modals from './modals';
import signatures from './signatures';
import { TogglePiiComponent } from './documents/toggle-pii/toggle-pii.component';
import { CursorPaginationComponent } from './cursor-pagination/cursor-pagination.component';
import { ManageAccessComponent } from './manage-access/manage-access.component';
import { JobTitleRequiredChangeComponent } from './job-title-required-change/job-title-required-change.component';
import { JobTitleRequiredComponent } from './job-title-required/job-title-required.component';
import { LabelsAssignComponent } from './labels-assign/labels-assign.component';
import { TagsAssignComponent } from './tags-assign/tags-assign.component';
import { MissingPermissionsComponent } from './missing-permissions/missing-permissions.component';
import { FolderStructureNodeComponent } from './folder-structure-node/folder-structure-node.component';
import { FolderStructureTreeComponent } from './folder-structure-tree/folder-structure-tree.component';
import { WarningModalComponent } from './warning-modal/warning-modal.component';
import { ApplyReusableStructureTemplateComponent } from './apply-reusable-structure-template/apply-reusable-structure-template.component';
import { ChooseStructureTemplateComponent } from './choose-structure-template/choose-structure-template.component';
import { TeamDeactivationMarkerComponent } from './deactivation-marker/deactivation-marker.component';
import { OldGenericTableComponent } from './tables/old-generic-table/old-generic-table.component';
import { WizardStepComponent } from './wizard/wizard-step/wizard-step.component';
import { WizardComponent } from './wizard/wizard.component';
import { BlindedRoleStatusComponent } from './blinded-status/blinded-status.component';

const widgetsModule = angular
    .module('app.widgets', [
        asyncFilter.name,
        breadcrumbs.name,
        dateTime.name,
        entity.name,
        icons.name,
        modals.name,
        signatures.name
    ])
    .directive('labelsAssign', downgradeComponent({ component: LabelsAssignComponent }) as ng.IDirectiveFactory)
    .directive('confirmDestroy', downgradeComponent({ component: ConfirmDestroyComponent }) as ng.IDirectiveFactory)
    .directive('cursorPagination', downgradeComponent({ component: CursorPaginationComponent }) as ng.IDirectiveFactory)
    .directive('reasonModal', downgradeComponent({ component: ReasonModalComponent }) as ng.IDirectiveFactory)
    .directive('warningModal', downgradeComponent({ component: WarningModalComponent }) as ng.IDirectiveFactory)
    .directive('togglePii', downgradeComponent({ component: TogglePiiComponent }) as ng.IDirectiveFactory)
    .directive('jobTitleRequiredChange', downgradeComponent({ component: JobTitleRequiredChangeComponent }) as ng.IDirectiveFactory)
    .directive('jobTitleRequired', downgradeComponent({ component: JobTitleRequiredComponent }) as ng.IDirectiveFactory)
    .directive('searchDropdown', downgradeComponent({ component: SearchDropdownComponent }) as ng.IDirectiveFactory)
    .directive('scrollableBox', downgradeComponent({ component: ScrollableBoxComponent }) as ng.IDirectiveFactory)
    .directive('timezonePicker', downgradeComponent({ component: TimezonePickerComponent }) as ng.IDirectiveFactory)
    .directive('toggle', downgradeComponent({ component: ToggleComponent }) as ng.IDirectiveFactory)
    .directive('dateTime', downgradeComponent({ component: DateTimeComponent }) as ng.IDirectiveFactory)
    .directive('checkboxTristate', downgradeComponent({ component: CheckboxTristateComponent }) as ng.IDirectiveFactory)
    .directive('pwdAndConfirmationInputs', downgradeComponent({ component: PwdAndConfirmationInputsComponent }) as ng.IDirectiveFactory)
    .directive('tableFilter', downgradeComponent({ component: TableFilterComponent }) as ng.IDirectiveFactory)
    .directive('pageWarning', downgradeComponent({ component: PageWarningComponent }) as ng.IDirectiveFactory)
    .directive('pageInfo', downgradeComponent({ component: PageInfoComponent }) as ng.IDirectiveFactory)
    .directive('warningWell', downgradeComponent({ component: WarningWellComponent }) as ng.IDirectiveFactory)
    .directive('uploadDropzone', downgradeComponent({ component: UploadDropzoneComponent }) as ng.IDirectiveFactory)
    .directive('uploadLink', downgradeComponent({ component: UploadLinkComponent }) as ng.IDirectiveFactory)
    .directive('dynamicFilter', downgradeComponent({ component: DynamicFilterComponent }) as ng.IDirectiveFactory)
    .directive('filterableList', downgradeComponent({ component: FilterableListComponent }) as ng.IDirectiveFactory)
    .directive('tagsAssign', downgradeComponent({ component: TagsAssignComponent }) as ng.IDirectiveFactory)
    .directive('actionBarItem', downgradeComponent({ component: ActionBarItemComponent }) as ng.IDirectiveFactory)
    .directive('nameEmail', downgradeComponent({ component: NameEmailComponent }) as ng.IDirectiveFactory)
    .directive('banner', downgradeComponent({ component: BannerComponent }) as ng.IDirectiveFactory)
    .directive('filteredSelect', downgradeComponent({ component: FilteredSelectComponent }) as ng.IDirectiveFactory)
    .directive('globalSearch', downgradeComponent({ component: GlobalSearchComponent }) as ng.IDirectiveFactory)
    .directive('navMenu', downgradeComponent({ component: NavMenuComponent }) as ng.IDirectiveFactory)
    .directive('virtualTree', downgradeComponent({ component: VirtualTreeComponent }) as ng.IDirectiveFactory)
    .directive('genericTable', downgradeComponent({ component: GenericTableComponent }) as ng.IDirectiveFactory)
    .directive('oldGenericTable', downgradeComponent({ component: OldGenericTableComponent }) as ng.IDirectiveFactory)
    .factory('globalSearch', downgradeInjectable(GlobalSearchService))
    .factory('globalSearch', downgradeInjectable(GlobalSearchService))
    .directive('manageAccess', downgradeComponent({ component: ManageAccessComponent }) as ng.IDirectiveFactory)
    .directive('folderStructureNode', downgradeComponent({ component: FolderStructureNodeComponent }) as ng.IDirectiveFactory)
    .directive('folderStructureTree', downgradeComponent({ component: FolderStructureTreeComponent }) as ng.IDirectiveFactory)
    .directive('missingPermissions', downgradeComponent({ component: MissingPermissionsComponent }) as ng.IDirectiveFactory)
    .directive('applyReusableStructureTemplate', downgradeComponent({ component: ApplyReusableStructureTemplateComponent }) as ng.IDirectiveFactory)
    .directive('chooseStructureTemplate', downgradeComponent({ component: ChooseStructureTemplateComponent }) as ng.IDirectiveFactory)
    .directive('teamDeactivationMarker', downgradeComponent({ component: TeamDeactivationMarkerComponent }) as ng.IDirectiveFactory)
    .directive('wizard', downgradeComponent({ component: WizardComponent }) as ng.IDirectiveFactory)
    .directive('wizardStep', downgradeComponent({ component: WizardStepComponent }) as ng.IDirectiveFactory)
    .directive('blindedRoleStatus', downgradeComponent({ component: BlindedRoleStatusComponent }) as ng.IDirectiveFactory);

export default widgetsModule;
