import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    OnDestroy
} from '@angular/core';
import { StateService } from '@uirouter/core';
import {
    BehaviorSubject,
    combineLatest,
    forkJoin, Observable, of, Subject
} from 'rxjs';
import {
    catchError, filter, map, take, takeUntil, tap
} from 'rxjs/operators';

import {
    Document,
    LogEntry,
    LogEntryTypes,
    Signature,
    SignatureRequest,
    SigningReasons,
    Team,
    User
} from '@app/shared/models';
import { CurrentSessionService } from '@app/core/current-session.service';
import { FilteredSelectEvent } from '@app/widgets/filtered-select/filtered-select.component';
import { CHECKBOX_STATES } from '@app/core/constants';
import { DocumentLogEntriesService } from '@app/shared/documents-log-entries/document-log-entries.service';
import { isDateInThePast } from '@app/shared/date-time/is-date-in-the-past.util';

import { calculateEntityPath, EntityPathItem } from '@app/shared/documents/calculate-entity-path.util';
import { LogEntrySignatureRequestsStore } from '../../services/log-entry-signature-requests.store.service';
import { LogEntrySignersTabDataRow, LogEntrySignersTabStatus } from '../../components/log-entry-signers-tab/log-entry-signers-tab.component';

import template from './log-entry-signature-requests.component.html';
import { LogEntryPendingTabDataRow } from '../../components/log-entry-pending-signatures-tab/log-entry-pending-signatures-tab.component';

@Component({
    selector: 'log-entry-signature-requests',
    template,
    providers: [
        { provide: '$scope', useExisting: '$rootScope' }
    ]
})
export class LogEntrySignatureRequestsComponent implements OnInit, OnDestroy {
    @Input() logDocument: Document;
    @Input() logEntry: LogEntry;
    @Output() dismiss = new EventEmitter<void>();
    @Input() preselectedSignerId: string;

    isPreselectedSignerLoaded = false;
    readonly commentMaxLength = 2000;
    comment = '';
    isSubmitting = false;
    loadingPendingRequests = false;
    loadingPotentialSigners = false;

    currentTeam: Team;
    currentUser: User;
    path: EntityPathItem[];
    pendingRequests$: Observable<SignatureRequest[]>;
    pendingTabData$: Observable<LogEntryPendingTabDataRow[]>;

    signersTabData$: Observable<LogEntrySignersTabDataRow[]>;
    selectedSigners$: Observable<User[]>;
    potentialSigners: User[] = [];

    canSubmit$: Observable<boolean>;
    readonly allHaveColumnSet$ = new BehaviorSubject<boolean>(true);
    readonly allHaveReasonSet$ = new BehaviorSubject<boolean>(false);
    destroy$ = new Subject<void>();

    signersTabStatus: LogEntrySignersTabStatus = null;

    constructor(
        private $state: StateService,
        private SignatureRequestsStore: LogEntrySignatureRequestsStore,
        private CurrentSession: CurrentSessionService,
        private DocumentLogEntries: DocumentLogEntriesService
    ) {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        this.currentUser = this.CurrentSession.getCurrentUser();
        this.selectedSigners$ = this.SignatureRequestsStore.selectedSigners$;
        this.signersTabData$ = this.SignatureRequestsStore.signersTabData$;

        this.pendingRequests$ = this.SignatureRequestsStore.pendingRequests$;
        this.pendingTabData$ = this.SignatureRequestsStore.pendingTabData$;
    }

    ngOnInit(): void {
        this.path = calculateEntityPath(this.logDocument);
        const {
            availableColumns,
            signersTabStatus
        } = this.resolveAvailableColumnsAndSignersTabStatus();
        this.signersTabStatus = signersTabStatus;
        this.SignatureRequestsStore.init(this.logEntry, this.currentUser, availableColumns);

        forkJoin([
            this.loadPotentialSigners(),
            this.loadPendingSignatureRequests()
        ]).pipe(take(1)).subscribe();

        this.SignatureRequestsStore.signersTabData$.pipe(
            takeUntil(this.destroy$),
            filter((rows) => !!rows && !!rows.length),
            tap((rows) => {
                let columnSetCount = 0;
                let reasonSetCount = 0;
                rows.forEach((row) => {
                    if (row.column) {
                        columnSetCount += 1;
                    }
                    if (row.reason) {
                        reasonSetCount += 1;
                    }
                });
                this.allHaveColumnSet$.next(rows.length === columnSetCount);
                this.allHaveReasonSet$.next(rows.length === reasonSetCount);
            })
        ).subscribe();

        this.canSubmit$ = combineLatest([
            this.pendingTabData$.pipe(map((data) => !!data.length)),
            this.selectedSigners$.pipe(map((signers) => !!signers.length)),
            this.allHaveColumnSet$,
            this.allHaveReasonSet$,
            this.getPendingTabChanged(),
            this.getIsPendingTabSignByDateValid(),
            this.getIsSignersTabSignByDateValid()
        ]).pipe(map(([
            pendingTabDataExists,
            signersAreSelected,
            allHaveColumnSet,
            allHaveReasonSet,
            pendingTabChanged,
            pendingTabSignByDateIsValid,
            signersTabSignByDateIsValid
        ]) => {
            let canSubmit = false;
            if (signersAreSelected && !pendingTabDataExists) {
                canSubmit = allHaveColumnSet && allHaveReasonSet && signersTabSignByDateIsValid;
            }
            if (!signersAreSelected && pendingTabDataExists) {
                canSubmit = pendingTabChanged && pendingTabSignByDateIsValid;
            }
            if (signersAreSelected && pendingTabDataExists) {
                canSubmit = allHaveColumnSet
                    && allHaveReasonSet
                    && signersTabSignByDateIsValid
                    && pendingTabSignByDateIsValid;
            }
            return canSubmit;
        }));
    }

    private getPendingTabChanged = (): Observable<boolean> => {
        return this.pendingTabData$.pipe(map((rows) => {
            return rows.some((row) => row.updated || row.markedAsCanceled);
        }));
    }

    private getIsPendingTabSignByDateValid = (): Observable<boolean> => {
        return this.pendingTabData$.pipe(
            map((rows) => rows.every((row) => {
                if (row.updated && row.signByDate) {
                    return !isDateInThePast(row.signByDate);
                }
                return true;
            }))
        );
    }

    private getIsSignersTabSignByDateValid = (): Observable<boolean> => {
        return this.signersTabData$.pipe(
            map((rows) => rows.every((row) => {
                if (row.signByDate) {
                    return !isDateInThePast(row.signByDate);
                }
                return true;
            }))
        );
    }

    private resolveAvailableColumnsAndSignersTabStatus(): {
        availableColumns: string[];
        signersTabStatus: LogEntrySignersTabStatus;
        } {
        const availableColumns: string[] = [];
        let signatureColumnsCount = 0;
        let signedColumnsCount = 0;
        let pendingColumnsCount = 0;
        this.logEntry.columns.forEach((c) => {
            if (c.type === LogEntryTypes.signature) {
                signatureColumnsCount += 1;
                if (!c.value && !c.pendingSignatureUserId) {
                    availableColumns.push(c.name);
                }
                else if (c.value) {
                    const { status } = c.value as Signature;
                    if (status === 'Signed') {
                        signedColumnsCount += 1;
                    }
                    else if (status === 'Pending' && c.pendingSignatureUserId) {
                        pendingColumnsCount += 1;
                    }
                    else if (status === 'Declined' && !c.pendingSignatureUserId) {
                        availableColumns.push(c.name);
                    }
                }
                else if (c.pendingSignatureUserId) {
                    pendingColumnsCount += 1;
                }
            }
        });
        let signersTabStatus = null;
        if (!availableColumns.length) {
            if (signatureColumnsCount === signedColumnsCount) {
                signersTabStatus = LogEntrySignersTabStatus.ALL_COLUMNS_HAVE_SIGNATURE;
            }
            else if (signatureColumnsCount === pendingColumnsCount) {
                signersTabStatus = LogEntrySignersTabStatus.ALL_COLUMNS_HAVE_PENDING;
            }
            else {
                signersTabStatus = LogEntrySignersTabStatus.NO_AVAILABLE_COLUMNS;
            }
        }
        return {
            availableColumns,
            signersTabStatus
        };
    }

    private loadPotentialSigners(filterValue?: string): Observable<User[]> {
        this.loadingPotentialSigners = true;

        return this.DocumentLogEntries.getPotentialSigners({
            documentId: this.logDocument.id as string,
            logEntryId: this.logEntry.id.logEntryId,
            filter: filterValue
        }).pipe(
            tap((data) => {
                this.potentialSigners = data;
                this.loadingPotentialSigners = false;
                if (
                    !this.isPreselectedSignerLoaded
                    && this.preselectedSignerId
                    && !this.signersTabStatus
                ) {
                    this.setPreselectedSigner();
                }
            }),
            catchError(() => {
                this.loadingPotentialSigners = false;
                this.dismiss.emit();
                return of([]);
            })
        );
    }

    private setPreselectedSigner(): void {
        const teamMember = this.potentialSigners.find((user) => user.id === this.preselectedSignerId);
        if (teamMember) {
            this.SignatureRequestsStore.selectSigners({
                added: [teamMember],
                removedIds: []
            });
        }
        this.isPreselectedSignerLoaded = true;
    }

    private loadPendingSignatureRequests(): Observable<SignatureRequest[]> {
        this.loadingPendingRequests = true;
        return this.DocumentLogEntries.getPendingSignatureRequests(
            this.logDocument.id as string,
            this.logEntry.id.logEntryId
        ).pipe(
            tap((data) => {
                this.loadingPendingRequests = false;
                this.SignatureRequestsStore.setPendingRequests(data);
            }),
            catchError(() => {
                this.dismiss.emit();
                return of([]);
            })
        );
    }

    get isProcessing(): boolean {
        return this.isSubmitting || this.loadingPendingRequests;
    }

    onSignersFilterChange($event: string): void {
        this.loadPotentialSigners($event).pipe(take(1)).subscribe();
    }

    onSelectSigners($event: FilteredSelectEvent<User>): void {
        this.SignatureRequestsStore.selectSigners($event);
    }

    onRemoveSigners($event: string[]): void {
        this.SignatureRequestsStore.selectSigners({
            removedIds: $event,
            added: []
        });
    }

    onColumnChange($event: { value: string; id: string }): void {
        this.SignatureRequestsStore.updateSignersTabDataColumn($event.value, $event.id);
    }

    onReasonChange($event: { value: SigningReasons; id: string }): void {
        this.SignatureRequestsStore.updateSignersTabData({ reason: $event.value }, $event.id);
    }

    onSignByDateChange($event: { value: Date; id: string }): void {
        this.SignatureRequestsStore.updateSignersTabData({ signByDate: $event.value }, $event.id);
    }

    onNotifyMeChange($event: { value: CHECKBOX_STATES; id: string }): void {
        this.SignatureRequestsStore.updateSignersTabData({ notifyMe: $event.value }, $event.id);
    }

    onEmailSignerChange($event: { value: CHECKBOX_STATES; id: string }): void {
        this.SignatureRequestsStore.updateSignersTabData({ emailSigner: $event.value }, $event.id);
    }


    onPendingReasonChange($event: { value: SigningReasons; id: string }): void {
        this.SignatureRequestsStore.updatePendingTabData({ reason: $event.value }, $event.id);
    }

    onPendingSignByDateChange($event: { value: Date; id: string }): void {
        this.SignatureRequestsStore.updatePendingTabData({ signByDate: $event.value }, $event.id);
    }

    onPendingNotifyMeChange($event: { value: CHECKBOX_STATES; id: string }): void {
        this.SignatureRequestsStore.updatePendingTabData({ notifyMe: $event.value }, $event.id);
    }

    onPendingEmailSignerChange($event: { value: CHECKBOX_STATES; id: string }): void {
        this.SignatureRequestsStore.updatePendingTabData({ emailSigner: $event.value }, $event.id);
    }

    onPendingMarkAsCanceled(ids: string[]): void {
        this.SignatureRequestsStore.markPendingTabDataAsCanceled(ids);
    }

    onPendingUndoChanges(ids: string[]): void {
        this.SignatureRequestsStore.undoPendingTabDataChanges(ids);
    }

    dismissModal(): void {
        if (!this.isProcessing) {
            this.dismiss.emit();
        }
    }

    onSubmit(): void {
        const payload = this.SignatureRequestsStore.getUpdateLogEntrySignatureRequestsPayload(this.logDocument);
        if (!payload) {
            this.dismissModal();
            return;
        }
        this.isSubmitting = true;
        payload.comment = this.comment;
        this.DocumentLogEntries.updateSignatureRequests(payload).pipe(
            take(1),
            tap(() => {
                this.isSubmitting = false;
                this.$state.go(this.$state.current, {}, { reload: true });
                this.dismissModal();
            }),
            catchError(() => {
                this.dismiss.emit();
                return of([]);
            })
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.SignatureRequestsStore.flushData();
    }
}
