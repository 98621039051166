import * as angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import teamsRoute from './teams.route';
import { ManageTeamMembersComponent } from './containers/manage-team-members/manage-team-members.component';
import { ManageTeamSettingsComponent } from './containers/manage-team-settings/manage-team-settings.component';
import { AddUsersComponent } from './components/add-users/add-users.component';
import { LabelCreateComponent } from './components/label-create/label-create.component';
import { DayPickerComponent } from './components/day-picker/day-picker.component';
import { ManageTeamProfileComponent } from './components/manage-team-profile/manage-team-profile.component';
import { ManageLabelsComponent } from './components/manage-labels/manage-labels.component';
import { SingleSignOnComponent } from './components/single-sign-on/single-sign-on.component';
import { TeamSipLinkCreateComponent } from './components/team-sip-link-create/team-sip-link-create.component';
import { ManageTeamSipLinksComponent } from './components/manage-team-sip-links/manage-team-sip-links.component';
import { ManageTeamStudiesComponent } from './components/manage-team-studies/manage-team-studies.component';
import { SingleSignOnService } from './single-sign-on.service';
import { TeamSipLinksService } from './team-sip-links.service';
import { ManageTeamSecurityComponent } from './components/manage-team-security/manage-team-security.component';
import { ManageTeamStudiesContainer } from './containers/manage-team-studies/manage-team-studies-container.component';


const dependencies = [
    uiRouter
];

const teamsModule = angular
    .module('app.teams', dependencies)
    .factory('SingleSignOn', downgradeInjectable(SingleSignOnService))
    .directive('manageTeamMembers', downgradeComponent({ component: ManageTeamMembersComponent }) as ng.IDirectiveFactory)
    .directive('manageTeamSettings', downgradeComponent({ component: ManageTeamSettingsComponent }) as ng.IDirectiveFactory)
    .directive('addUsers', downgradeComponent({ component: AddUsersComponent }) as ng.IDirectiveFactory)
    .directive('dayPicker', downgradeComponent({ component: DayPickerComponent }) as ng.IDirectiveFactory)
    .directive('manageTeamProfile', downgradeComponent({ component: ManageTeamProfileComponent }) as ng.IDirectiveFactory)
    .directive('manageLabels', downgradeComponent({ component: ManageLabelsComponent }) as ng.IDirectiveFactory)
    .directive('teamSipLinkCreate', downgradeComponent({ component: TeamSipLinkCreateComponent }) as ng.IDirectiveFactory)
    .directive('labelCreate', downgradeComponent({ component: LabelCreateComponent }) as ng.IDirectiveFactory)
    .directive('manageStudiesByTeam', downgradeComponent({ component: ManageTeamStudiesContainer }) as ng.IDirectiveFactory)
    .directive('manageTeamSipLinks', downgradeComponent({ component: ManageTeamSipLinksComponent }) as ng.IDirectiveFactory)
    .directive('manageTeamStudies', downgradeComponent({ component: ManageTeamStudiesComponent }) as ng.IDirectiveFactory)
    .directive('singleSignOn', downgradeComponent({ component: SingleSignOnComponent }) as ng.IDirectiveFactory)
    .directive('manageTeamSecurity', downgradeComponent({ component: ManageTeamSecurityComponent }) as ng.IDirectiveFactory)
    .factory('TeamSipLinks', downgradeInjectable(TeamSipLinksService))
    .config(teamsRoute);

export default teamsModule;
