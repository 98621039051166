import { VirtualTreeSelectionMode } from '@app/widgets/virtual-tree/virtual-tree.component.types';
import {
    Binder, BrowseTree, Document, Folder, Timeline,
    TimelineItem
} from '@app/shared/models';
import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import style from './timeline-form.component.scss';
import template from './timeline-form.component.html';
import { FieldErrors, OnSelectedItemValue } from './timeline-form.component.types';

@Component({
    selector: 'timeline-form',
    template,
    styles: [String(style)],
    providers: [{ provide: '$scope', useExisting: '$rootScope' }] // date-time-picker workaround
})
export class TimelineFormComponent implements OnInit {
    @Input() bindersList: Binder[] = [];
    @Input() loadBinder: () => BrowseTree | Promise<void>;
    @Input() timeline: Timeline;
    @Input() selectedItems: TimelineItem[];
    @Input() fieldErrors: FieldErrors;
    @Input() globalErrors: string[];

    @Output() onNameChange = new EventEmitter<{ name: string }>();
    @Output() onIsCompleteChange = new EventEmitter<{ isComplete: boolean }>();
    @Output() onSelectedItemsChange = new EventEmitter<{ selectedItems: OnSelectedItemValue[] }>();

    selectionMode: typeof VirtualTreeSelectionMode;

    createTimelineForm: FormGroup;

    binderTreeLabel: string;
    constructor(
        private fb: FormBuilder
    ) {
        this.selectionMode = VirtualTreeSelectionMode;
    }

    startDate: Date;
    endDate: Date;

    bsConfig = {
        dateInputFormat: 'DD-MMM-YYYY',
        containerClass: 'theme-default',
        showWeekNumbers: false
    };

    ngOnInit(): void {
        this.binderTreeLabel = this.hasVisibleBinders()
            ? 'Select Documents, Placeholders, and Shortcuts to include in Timeline:'
            : 'This user does not have permissions to view Binder/Documents';
        this.initForm();
    }

    initForm() {
        this.createTimelineForm = this.fb.group({
            timelineName: [this.timeline.name, [Validators.required]]
        });

        this.startDate = this.timeline.projectedStart ? new Date(this.timeline.projectedStart) : null;
        this.endDate = this.timeline.projectedEnd ? new Date(this.timeline.projectedEnd) : null;
    }

    isItemSelectable(item: Document | Folder | Binder): boolean {
        return item.type === 'document';
    }

    isItemDisabled(item: Document | Folder | Binder): boolean {
        return item.type === 'document' && item.subType !== 'shortcut' && item.isLocked;
    }

    isItemDisplayable(itm: Document | Folder | Binder): boolean {
        const _hasSelectableContent = (item) => {
            if (item.type !== 'folder') {
                return true;
            }

            if (!item.items || item.items.length === 0) {
                return false;
            }

            return item.items.reduce((accum, child) => accum || _hasSelectableContent(child), false);
        };

        return _hasSelectableContent(itm);
    }

    showItemCheckbox(item: Document): boolean {
        return !item.isLocked;
    }

    isItemLoadableOnSelect(item: Document | Folder | Binder): boolean {
        return item.type === 'binder';
    }

    binderTreeSelectionChange = (event: { selectedItems: OnSelectedItemValue[] }) => {
        this.onSelectedItemsChange.emit({ selectedItems: event.selectedItems });
    }

    nameChanged() {
        this.onNameChange.emit({ name: this.createTimelineForm.value.timelineName.trim() });
    }

    isCompletedStatusChange(isComplete: boolean) {
        this.onIsCompleteChange.emit({ isComplete });
    }

    private hasVisibleBinders(): boolean {
        return !!this.bindersList.length;
    }

    setStartDate(value: Date): void {
        this.startDate = value;
        this.timeline.projectedStart = this.startDate?.toISOString();
    }

    setEndDate(value: Date): void {
        this.endDate = value;
        this.timeline.projectedEnd = this.endDate?.toISOString();
    }
}
