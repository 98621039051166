import { Subject, BehaviorSubject, of } from 'rxjs';
import { CurrentSessionService } from '@app/core/current-session.service';
import {
    Team,
    AuditTrailSubject,
    LogTemplate,
    Crumb,
    BatchResponseItem
} from '@app/shared/models';
import {
    Component, OnInit
} from '@angular/core';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { AuditTrailService } from '@app/shared/audit-trail/audit-trail.service';
import { PaginationPropertiesService } from '@app/shared/pagination-properties/pagination-properties.service';
import {
    GetLogTemplatesFilter,
    TemplateSortableProperties,
    SortDirection
} from '@app/shared/log-templates/log-templates.service.types';
import { LogTemplatesService } from '@app/shared/log-templates/log-templates.service';
import { StateService } from '@uirouter/angularjs';
import { SelectionModel } from '@angular/cdk/collections';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import {
    tap, take, takeUntil, filter, catchError, last
} from 'rxjs/operators';
import { AuditTrailTypes } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component.types';
import { ApiErrorsService } from '@app/shared/api-error/api-errors.service';
import { AuditTrailModalComponent } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component';
import { LogTemplateState, Pagination } from './team-log-templates.component.types';
import { LogTemplateDuplicatedEvent } from '../../components/duplicate-log-template/duplicate-log-template.component.types';
import { DuplicateLogTemplateComponent } from '../../components/duplicate-log-template/duplicate-log-template.component';

import componentTemplate from './team-log-templates.component.html';
import styles from './team-log-templates.component.scss';
import { LogTemplateType } from '../../components/log-template-type-selector/log-template-type-selector.component.types';
import { ROUTES } from '../../../../core/constants';
import { FeatureFlagService } from '../../../../core/feature-flag.service';
import { FEATURE_FLAGS } from '../../../../core/constants/feature-flags';

@Component({
    selector: 'team-log-templates',
    template: componentTemplate,
    styles: [String(styles)]
})
export class TeamLogTemplatesComponent implements OnInit {
    private viewActiveTemplates = true;
    private selectedTemplates = new SelectionModel<LogTemplate>(true);
    crumbs: Crumb[] = [];
    currentTeam: Team;
    logTemplates: LogTemplate[];
    hasNext: boolean;
    isLoading = true;
    pageSize = 20;
    ITEMS_PER_PAGE = 20;
    filter = <GetLogTemplatesFilter>{
        isActive: this.viewActiveTemplates,
        name: ''
    };

    showBoundaryLinkNumbers: boolean;
    maxSize: number;
    pagination: Pagination = {
        pageNum: 1,
        pageSize: this.ITEMS_PER_PAGE
    };

    uiSref: string;
    duplicatedTemplateId: string;
    logType: LogTemplateType;
    private buttonText = new BehaviorSubject<string>(null);
    buttonText$ = this.buttonText.asObservable();
    public readonly tooltipDelay = 200;
    public canActOnSelection = false;

    private readonly destroy$ = new Subject<void>();

    constructor(
        private $state: StateService,
        private CurrentSession: CurrentSessionService,
        private AuditTrails: AuditTrailService,
        private LogTemplates: LogTemplatesService,
        private Notifications: NotificationsService,
        private Modals: ModalsService,
        private featureFlagService: FeatureFlagService,
        private ApiError: ApiErrorsService,
        private PaginationProperties: PaginationPropertiesService

    ) {}

    ngOnInit(): void {
        this.currentTeam = this.CurrentSession.getCurrentTeam();
        if (!this.currentTeam.settings.features.logTemplates || !this.currentTeam.permissions.viewLogTemplates) {
            this.$state.go('app.select-team');
        }

        this.setLogTypeFromRouteData();
        if (this.logType === LogTemplateType.DOA) {
            this.redirectIfFeatureFlagDisabled();
        }
        this.setUiSref();
        this.setButtonText();
        this.setCrumbs();
        this.getLogTemplates(this.pagination);
        this.setCanActOnSelection();
        this.showBoundaryLinkNumbers = this.PaginationProperties.showBoundaryLinkNumbers();
        this.maxSize = this.PaginationProperties.getMaxSize();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    actionsColumnClick(logTemplate: LogTemplate): void {
        if (this.selectedTemplates.selected.length > 1) {
            return;
        }

        this.selectSingleTemplate(logTemplate);
    }

    setCanActOnSelection(): void {
        this.canActOnSelection = this.selectedTemplates.selected.length > 1
            || this.canEditTemplate()
            || this.canViewAuditTrail()
            || this.canMarkTemplates();
    }

    canCreateTemplate(): boolean {
        return this.currentTeam.permissions.viewLogTemplates
            && this.currentTeam.permissions.createLogTemplates;
    }

    canEditTemplate(): boolean {
        return this.viewActiveTemplates
            && this.onlyOneTemplateSelected()
            && this.currentTeam.permissions.viewLogTemplates
            && this.currentTeam.permissions.editLogTemplates
            && !this.selectedTemplates.selected[0]?.referenceId;
    }

    canMarkTemplates(): boolean {
        return Boolean(this.selectedTemplates.selected.length)
            && this.currentTeam.permissions.viewLogTemplates
            && this.currentTeam.permissions.archiveLogTemplates;
    }

    canDuplicateLogTemplate(): boolean {
        return this.viewActiveTemplates
            && this.onlyOneTemplateSelected()
            && this.currentTeam.permissions.viewLogTemplates
            && this.currentTeam.permissions.duplicateLogTemplates;
    }

    canViewAuditTrail(): boolean {
        return this.onlyOneTemplateSelected() && this.currentTeam.permissions.viewTeamAuditTrail;
    }

    onCreateTemplateClick(): void {
        if (this.logType === LogTemplateType.DOA) {
            this.$state.go('app.team.doa-log-template-create');
        }
        else {
            this.$state.go('app.team.log-template-create');
        }
    }

    editTemplate(): void {
        const logTemplateId = this.getFirstSelectedTemplate().id.templateId;

        this.$state.go(this.uiSref, {
            logTemplateId
        });
    }

    openAuditTrailModal(): void {
        if (this.currentTeam.permissions.viewTeamAuditTrail) {
            const template = this.getFirstSelectedTemplate();

            const auditTrailParams = {
                permissions: this.currentTeam.permissions,
                name: template.name,
                id: template.id.templateId,
                teamId: this.currentTeam.id,
                type: AuditTrailTypes.logTemplate
            };

            this.AuditTrails.getAudits({
                subject: AuditTrailSubject.LOG_TEMPLATE,
                teamId: this.currentTeam.id,
                objectId: auditTrailParams.id,
                overwrittenObjectId: null,
                limitToOverwritten: false,
                ...this.AuditTrails.auditPagination
            }).toPromise().then((audits) => {
                this.Modals.show(AuditTrailModalComponent, {
                    class: 'modal-lg',
                    initialState: {
                        data: audits,
                        item: auditTrailParams,
                        subject: AuditTrailSubject.LOG_TEMPLATE,
                        pagination: this.AuditTrails.auditPagination,
                        onPageChange: this.AuditTrails.getAudits.bind(this.AuditTrails)
                    }
                });

            }).catch(this.ApiError.handleError);
        }
    }

    openDuplicateTemplateModal(): void {
        const selectedTemplate = this.getFirstSelectedTemplate();

        const duplicateLogTemplateModalRef = this.Modals.show(DuplicateLogTemplateComponent, {
            initialState: {
                originalTemplateName: selectedTemplate.name,
                originalTemplateId: selectedTemplate.id.templateId,
                teamId: this.currentTeam.id
            }
        });

        duplicateLogTemplateModalRef.content.duplicated
            .pipe(take(1))
            .subscribe((event: LogTemplateDuplicatedEvent) => {
                this.logTemplates.push(event.logTemplate);
                this.duplicatedTemplateId = event.logTemplate.id.templateId;

                setTimeout(() => {
                    this.duplicatedTemplateId = undefined;
                }, 5000);
            });
    }

    applyFilter(nameFilter: string): void {
        this.filter.name = nameFilter || '';
        this.getLogTemplates(this.pagination);
    }

    markTemplates(): void {
        if (!this.selectedTemplates.selected.length) {
            return;
        }

        const state: LogTemplateState = this.viewActiveTemplates ? 'inactive' : 'active';

        this.LogTemplates.markLogTemplates(this.currentTeam.id, {
            templateIds: this.selectedTemplates.selected.map((template) => template.id.templateId),
            isActive: !this.viewActiveTemplates
        }).subscribe((response) => {
            this.notify(response, state);
            this.removeMarkedLogTemplates(response);
            this.setCanActOnSelection();
        }, (error) => {
            if (error && error.error && error.error.message) {
                this.Notifications.error(error.error.message);
            }
            else {
                this.Notifications.unexpectedError();
            }
        });
    }

    viewTemplates(isActive: boolean): void {
        if (this.viewActiveTemplates === isActive) {
            return;
        }

        this.selectedTemplates.clear();
        this.canActOnSelection = false;
        this.viewActiveTemplates = isActive;
        this.filter.name = '';
        this.filter.isActive = this.viewActiveTemplates;
        this.setPagination(1);
        this.getLogTemplates(this.pagination);
    }

    isTemplateSelected(logTemplate: LogTemplate): boolean {
        return this.selectedTemplates.isSelected(logTemplate);
    }

    toggleTemplate(logTemplate: LogTemplate): void {
        this.selectedTemplates.toggle(logTemplate);
        this.setCanActOnSelection();
    }

    selectSingleTemplate(logTemplate: LogTemplate): void {
        this.selectedTemplates.clear();
        this.selectedTemplates.select(logTemplate);
        this.setCanActOnSelection();
    }

    private setLogTypeFromRouteData(): void {
        const { data } = this.$state.current;
        this.logType = data.logType;
        this.filter.type = this.logType;
    }

    private setCrumbs(): void {
        this.crumbs = [
            {
                name: 'Log Templates',
                stateName: 'app.team.log-templates'
            }
        ];

        const currentPageCrumb = <Crumb>{};
        if (this.logType === LogTemplateType.DOA) {
            currentPageCrumb.name = 'DOA Log Templates';
        }
        else {
            currentPageCrumb.name = 'eLog Templates';
        }
        this.crumbs.push(currentPageCrumb);
    }

    private getButtonText(): string {
        let buttonText: string;

        switch (this.logType) {
            case LogTemplateType.DOA:
                buttonText = 'Create DOA Log Template';
                break;
            default:
                buttonText = 'Create Template';
                break;
        }

        return buttonText;
    }

    private setButtonText(): void {
        const buttonText = this.getButtonText();

        this.buttonText.next(buttonText);
    }

    private reloadLogTemplates(): void {
        this.logTemplates = [];
        this.setPagination(1);
        this.getLogTemplates(this.pagination);
    }

    private onlyOneTemplateSelected(): boolean {
        return this.selectedTemplates.selected.length === 1;
    }

    private getFirstSelectedTemplate(): LogTemplate {
        return this.selectedTemplates.selected[0];
    }

    private notify(responseItems: BatchResponseItem<LogTemplate>[], state: LogTemplateState): void {
        let successes = 0;
        let failures = 0;
        responseItems.forEach((item) => {
            item.statusCode === 200
                ? successes += 1
                : failures += 1;
        });

        if (responseItems.length === 1) {
            return this.notifyOne(Boolean(successes), state);
        }

        this.notifyMany(successes, failures, state);
    }

    private notifyOne(isSuccess: boolean, state: LogTemplateState): void {
        const successMessage = this.selectedTemplates.selected[0].templateType === LogTemplateType.ELOG
            ? `Log template made ${state}.`
            : `DOA Log template made ${state}.`;
        const errorMessage = this.selectedTemplates.selected[0].templateType === LogTemplateType.ELOG
            ? `Log template was not made ${state}.`
            : `DOA Log template was not made ${state}.`;
        isSuccess
            ? this.Notifications.success(successMessage)
            : this.Notifications.error(errorMessage);
    }

    private notifyMany(successes: number, failures: number, state: LogTemplateState): void {
        if (successes) {
            const templates = successes === 1 ? 'template' : 'templates';
            const successMessage = this.selectedTemplates.selected[0].templateType === LogTemplateType.ELOG
                ? `${successes} Log ${templates} made ${state}.`
                : `${successes} DOA Log ${templates} made ${state}.`;
            this.Notifications.success(successMessage);
        }

        if (failures) {
            const templates = failures === 1 ? 'template was' : 'templates were';
            const errorMessage = this.selectedTemplates.selected[0].templateType === LogTemplateType.ELOG
                ? `${failures} Log ${templates} not made ${state}.`
                : `${failures} DOA Log ${templates} not made ${state}.`;
            this.Notifications.error(errorMessage);
        }
    }

    private removeMarkedLogTemplates(responseItems: BatchResponseItem<LogTemplate>[]): void {
        const allSucceeded = responseItems.every((item) => item.statusCode === 200);

        if (allSucceeded) {
            this.removeSelectedTemplates();
        }

        if (!allSucceeded || !this.logTemplates.length) {
            this.reloadLogTemplates();
        }

        this.selectedTemplates.clear();
    }

    private removeSelectedTemplates(): void {
        this.logTemplates = this.logTemplates.filter((logTemplate) => {
            return !this.selectedTemplates.isSelected(logTemplate);
        });
    }

    private setUiSref(): void {
        this.uiSref = this.logType === LogTemplateType.DOA ? ROUTES.doaLogTemplateView : ROUTES.logTemplateView;
    }

    private redirectIfFeatureFlagDisabled(): void {
        this.featureFlagService.getFlag(FEATURE_FLAGS.DOA_LOG_TEMPLATES, false)
            .pipe(
                filter((isFlagEnabled) => isFlagEnabled !== undefined),
                tap((isFlagEnabled) => {
                    if (isFlagEnabled === false) {
                        this.$state.go(ROUTES.elogs, { teamId: this.currentTeam.id });
                    }
                }),
                takeUntil(this.destroy$)
            ).subscribe();
    }


    private getLogTemplates(pagination: Pagination): void {
        this.isLoading = true;

        const getLogTemplatesParams = {
            pageSize: pagination.pageSize,
            pageNum: pagination.pageNum,
            filter: this.filter,
            sortBy: TemplateSortableProperties.UPDATED_AT,
            sortDir: SortDirection.DESC
        };

        this.LogTemplates.getLogTemplates(this.currentTeam.id, getLogTemplatesParams)
            .pipe(
                tap((data) => {
                    this.logTemplates = data.items;
                    this.setPagination(pagination.pageNum, data.totalCount);
                    this.isLoading = false;
                }),
                catchError(({ error }) => {
                    if (error?.message) {
                        this.Notifications.error(error.message);
                    }
                    else {
                        this.Notifications.unexpectedError();
                    }
                    return of(null);
                }),
                take(1)
            ).subscribe();
    }

    handlePageChange(pagination: { page: number, itemsPerPage: number }) {
        if (pagination.page === this.pagination.pageNum) {
            return;
        }
        this.getLogTemplates({
            pageNum: pagination.page,
            pageSize: pagination.itemsPerPage
        });
    }

    private setPagination(pageNum = 1, totalCount?: number): void {
        this.pagination.pageNum = pageNum;
        this.pagination.totalCount = totalCount || 0;
    }
}
