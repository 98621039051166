import * as _ from 'lodash';
import {
    AuditTrailSubject, Role, Team, TeamPermissions, User
} from '@app/shared/models';
import { ConfirmDestroySubmitEvent } from '@app/widgets/confirm-destroy/confirm-destroy.component.types';
import { ManageAccessSubmitEvent } from '@app/widgets/manage-access/manage-access.component.types';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { AuditTrailModalComponent } from '@app/components/audit-trail/components/audit-trail-modal/audit-trail-modal.component';
import { Component, OnInit } from '@angular/core';
import { SORT } from '@app/core/constants';
import { PaginationPropertiesService } from '@app/shared/pagination-properties/pagination-properties.service';
import { AccessDatesService } from '@app/shared/access-dates/access-dates.service';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { TeamService } from '@app/shared/teams/team.service';
import { AuditTrailService } from '@app/shared/audit-trail/audit-trail.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { StateService } from '@uirouter/core';
import { ConfirmDestroyComponent } from '@app/widgets/confirm-destroy/confirm-destroy.component';
import { ManageAccessComponent } from '@app/widgets/manage-access/manage-access.component';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { AccessDatesReportComponent } from '../../components/access-dates-report/access-dates-report.component';
import { AddUsersComponent } from '../../components/add-users/add-users.component';
import { AddUsersEvent } from '../../components/add-users/add-users.component.types';
import { Pagination, LoadUsersResponse, UserWithRoles } from './manage-team-members.component.types';
import template from './manage-team-members.component.html';

@Component({
    selector: 'manage-team-members',
    template
})
export class ManageTeamMembersComponent implements OnInit {
    crumbs = [{ name: 'Manage Team Members' }];
    SORT = SORT;
    loadingUsers = false;
    loadedUsers = false;

    isNewAccessDates: boolean;
    currentTeam: Team;
    currentUser: User;
    showBoundaryLinkNumbers: boolean;
    maxSize: number;
    pagination: Pagination;
    users: LoadUsersResponse;
    selectedUser: UserWithRoles;
    sortDir: string;

    filterValue;

    constructor(
        private $state: StateService,
        private teamsService: TeamService,
        private currentSessionService: CurrentSessionService,
        private auditTrailService: AuditTrailService,
        private accessDatesService: AccessDatesService,
        private paginationPropertiesService: PaginationPropertiesService,
        private featureFlagService: FeatureFlagService,
        private notificationsService: NotificationsService,
        private modalsService: ModalsService
    ) {

        this.maxSize = paginationPropertiesService.getMaxSize();
        this.showBoundaryLinkNumbers = paginationPropertiesService.showBoundaryLinkNumbers();
        this.pagination = {
            pageNum: 1,
            pageSize: 20,
            sortBy: 'profile.firstName',
            sortDir: 'ASC',
            totalItems: 0
        };
    }

    ngOnInit(): void {
        this.currentTeam = this.currentSessionService.getCurrentTeam();
        this.currentUser = this.currentSessionService.getCurrentUser();

        if (!this.canManageTeamMembers(this.currentTeam.permissions)) {
            this.$state.go('app.select-team');
        }
        this.crumbs = [{ name: `Manage Team Members: ${this.currentTeam.name}` }];
        this.fetchUsers();
        this.featureFlagService.getFlag(FEATURE_FLAGS.NEW_ACCESS_DATES_REPORT, true).subscribe((value: boolean) => {
            this.isNewAccessDates = value;
        });
    }

    private canManageTeamMembers(perm: TeamPermissions): boolean {
        return perm.manageTeam
            || perm.viewTeamUsersRolesPermissions
            || perm.inviteUsers
            || perm.uninviteUsers;
    }

    private filterTeamRolesOnUsers(): void {

        this.users.items.forEach((user) => {

            user.roles = user.roles.filter((role) => this.currentTeam.id === role.teamId && !role.isHiddenRole);
        });
    }

    addUsers(): void {
        if (!this.canAddUsers()) {
            return;
        }
        const addUsersModal = this.modalsService.show(AddUsersComponent, {
            animated: false,
            class: 'modal-md',
            initialState: {}
        });
        addUsersModal.content.onAddUser.subscribe((event: AddUsersEvent) => {
            const { success } = event;
            if (success) {
                this.pageChanged({ page: this.pagination.pageNum });
            }
        });
    }

    openAuditTrailModal(): void {
        if (this.canViewAuditTrail()) {
            const params = {
                subject: AuditTrailSubject.TEAM_MEMBERS,
                teamId: this.currentTeam.teamId,
                objectId: this.currentTeam.id,
                overwrittenObjectId: null,
                limitToOverwritten: false,
                ...this.auditTrailService.auditPagination
            };


            this.auditTrailService.getAudits(params).subscribe((audits) => {
                this.modalsService.show(AuditTrailModalComponent, {
                    class: 'modal-lg',
                    initialState: {
                        data: audits,
                        item: this.currentTeam as any,
                        subject: params.subject,
                        pagination: this.auditTrailService.auditPagination,
                        onPageChange: this.auditTrailService.getAudits.bind(this.auditTrailService)
                    }
                });

            }, (errorResponse) => {
                const message = errorResponse && errorResponse.error && errorResponse.error.message;
                if (message) {
                    this.notificationsService.error(message);
                }
                else {
                    this.notificationsService.unexpectedError();
                }
            });
        }
    }

    openPermissionReport(user: User): void {
        if (this.canViewPermissionOverview()) {
            this.$state.go('app.team.permission-report', {
                teamId: this.currentTeam.id,
                subjectId: user.id,
                subjectType: 'user'
            });
        }
    }

    cancelInvitation(): void {
        if (this.canUninviteUsers()) {
            const displayName = _.escape(this.selectedUser.name || this.selectedUser.email);
            const bodyText = 'This action <span class="strong text-uppercase">cannot</span> be undone. This will permanently remove or cancel the '
            + `invitation for <span class="strong">${displayName}</strong>.`;

            const modalInstance = this.modalsService.show(ConfirmDestroyComponent, {
                animated: false,
                initialState: {
                    bodyText,
                    confirmVerb: 'Remove',
                    confirmVerbProcessing: 'Removing'
                },
                class: 'modal-md'
            });

            modalInstance.content.save.subscribe((event: ConfirmDestroySubmitEvent) => {
                const user = _.find(this.users.items, 'selected');
                this.teamsService.removeUserFromTeam(user).subscribe(() => {

                    this.selectedUser = undefined;
                    this.fetchUsers();
                    event.onSuccess();
                }, event.onError);
            });

            modalInstance.content.dismiss.subscribe(() => modalInstance.hide());
        }
    }

    resendInvitation() {
        if (this.canReinviteUsers()) {
            const selectedUser = _.find(this.users.items, 'selected');
            this.teamsService.resendInvitation(selectedUser).subscribe();
        }
    }

    select(user: UserWithRoles) {
        if (user.selected) {
            user.selected = false;
            this.selectedUser = undefined;
        }
        else {
            this.users.items.forEach((userItem) => {
                userItem.selected = false;
            });

            user.selected = true;
            this.selectedUser = user;
        }
    }

    toggleActions($event: PointerEvent, user: UserWithRoles) {
        $event.preventDefault();
        if (this.selectedUser !== user) {
            this.select(user);
        }
    }

    assignUserRoles() {
        if (!this.canManageUsers()) {
            return;
        }

        const userId = this.selectedUser.id;
        const teamId = this.currentTeam.id;

        this.accessDatesService.getRolesForUser({ userId, teamId }).subscribe((userRoles) => {
            const items = userRoles;

            const modalInstance = this.modalsService.show(ManageAccessComponent, {
                animated: false,
                class: 'modal-lg',
                keyboard: false,
                initialState: {
                    items,
                    canAssignDates: this.currentTeam.permissions.manageTeamAccessControl,
                    canAssignRoles: this.currentTeam.permissions.assignTeamRoles,
                    subject: _.clone(this.selectedUser)
                }
            });

            modalInstance.content.submit.subscribe((event: ManageAccessSubmitEvent) => {
                const {
                    creates, updates, deletes, sames
                } = event.data;
                this.teamsService.updateRoleAssignmentMultiple(teamId, { creates, updates, deletes })
                    .subscribe((data) => {
                        event.onSuccess();

                        const createdRolesForUser = data ? data.created.map((userRole) => userRole.role) : [];
                        const updatedRolesForUser = data ? data.updated.map((userRole) => userRole.role) : [];
                        this.selectedUser.roles = (sames as Role[]).concat(createdRolesForUser, updatedRolesForUser);
                    }, event.onError);
            });

            modalInstance.content.dismiss.subscribe(() => modalInstance.hide());
        }, (error) => {
            this.notificationsService.error(error.error.message);
        });
    }

    removeAllPermissions(user: User): void {
        if (!this.canRemoveAllPermissions()) {
            return;
        }
        this.accessDatesService.getRolesForUser({ teamId: this.currentTeam.id, userId: user.id })
            .subscribe((userRoles) => {
                let bodyText = '<div>This action cannot be undone. It will <b>Remove ALL Permissions</b> this user has on the team.</div>';
                if (userRoles.length) {
                    bodyText += '<div>This action will also detach the user from the following roles:<ul>';
                    userRoles.forEach((userRole) => {
                        bodyText += `<li><strong>${userRole.role.name}</strong></li>`;
                    });
                    bodyText += '</ul></div>';
                }
                bodyText += '<div>ARE YOU SURE you want to go ahead with this action?</div>';

                const modalInstance = this.modalsService.show(ConfirmDestroyComponent, {
                    animated: false,
                    initialState: {
                        bodyText,
                        confirmVerb: 'Remove ALL Permissions',
                        confirmVerbProcessing: 'Saving'
                    },
                    class: 'modal-md'
                });
                modalInstance.content.save.subscribe((event: ConfirmDestroySubmitEvent) => {
                    this.teamsService.executeRemoveAllPermissions(this.currentTeam.id, user).subscribe(() => {
                        event.onSuccess();
                        this.$state.reload();
                    }, event.onError);
                });
                modalInstance.content.dismiss.subscribe(() => modalInstance.hide());
            });
    }

    isCurrentUser(): boolean {
        return this.selectedUser && this.currentUser.id === this.selectedUser.id;
    }

    canAddUsers(): boolean {
        return this.currentTeam.permissions.inviteUsers;
    }

    canViewAuditTrail(): boolean {
        return this.currentTeam.permissions.viewTeamAuditTrail;
    }

    canManageUsers(): boolean {
        return this.selectedUser && this.currentTeam.permissions.viewTeamUsersRolesPermissions;
    }

    canReinviteUsers(): boolean {
        return this.currentTeam.permissions.inviteUsers
            && this.selectedUser
            && !this.selectedUser.isConfirmed;
    }

    canUninviteUsers(): boolean {
        return this.currentTeam.permissions.uninviteUsers && this.selectedUser && !this.isCurrentUser();
    }

    canViewPermissionOverview(): boolean {
        return this.selectedUser && this.currentTeam.permissions.viewTeamUsersRolesPermissions;
    }

    canRemoveAllPermissions(): boolean {
        return this.currentTeam.permissions.manageTeamRolesAndPermissions && this.selectedUser && !this.isCurrentUser();
    }

    canActOnSelection(): boolean {
        return Boolean(this.canUninviteUsers()
            || this.canManageUsers()
            || this.canViewPermissionOverview()
            || this.canRemoveAllPermissions()
            || this.canReinviteUsers());
    }

    sortChanged(sortProperty: string): void {

        if (this.pagination.sortBy === sortProperty) {
            this.pagination.sortDir = this.pagination.sortDir === 'DESC' ? 'ASC' : 'DESC';
        }
        this.pagination.sortBy = sortProperty;
        this.fetchUsers();
    }

    pageChanged({ page }: { page: number }): void {

        this.pagination.pageNum = page;
        this.fetchUsers();
    }

    private fetchUsers() {

        const params = {
            teamId: this.currentTeam.teamId,
            filter: this.filterValue === '' ? undefined : this.filterValue,
            ...this.pagination
        };

        this.loadingUsers = true;
        this.teamsService.loadUsers(params).subscribe((users) => {
            this.loadedUsers = true;
            this.sortDir = this.pagination.sortDir;
            this.users = { recordCount: users.recordCount, items: users.items as UserWithRoles[] };
            this.users.items = sortByLexicographically(this.users.items, this.pagination.sortBy as any);
            this.pagination.totalItems = users.recordCount;
            this.filterTeamRolesOnUsers();
            this.loadingUsers = false;
        });
    }

    applyFilter(filter) {
        this.filterValue = filter;
        this.pagination.pageNum = 1;
        this.selectedUser = undefined;
        this.fetchUsers();
    }

    canViewAccessDatesReport(): boolean {
        return this.currentTeam.permissions.viewTeamUsersRolesPermissions;
    }

    openAccessDatesReport(): void {
        if (this.canViewAccessDatesReport()) {
            this.modalsService.show(AccessDatesReportComponent, {
                animated: false,
                class: this.isNewAccessDates ? 'modal-xl' : 'modal-lg',
                initialState: undefined
            });
        }
    }
}
