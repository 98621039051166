import * as moment from 'moment-timezone';
import {
    Component, OnInit, Output, EventEmitter
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import template from './document-set-date.component.html';
import styles from './document-set-date.component.scss';

@Component({
    selector: 'document-set-date',
    template,
    providers: [{ provide: '$scope', useExisting: '$rootScope' }], // date-time-picker workaround
    styles: [String(styles)]

})

export class DocumentSetDateComponent implements OnInit {
    @Output() save = new EventEmitter<{date: Date}>();

    date: Date;
    minDate: Date;
    maxDate: Date;
    isValid: boolean;

    bsConfig = {
        dateInputFormat: 'DD-MMM-YYYY',
        containerClass: 'theme-default',
        keepDatesOutOfRules: true,
        showWeekNumbers: false
    };

    constructor(
        private modalRef: BsModalRef
    ) {
    }

    ngOnInit(): void {
        this.minDate = moment().subtract(2, 'years').toDate();
        this.maxDate = moment(new Date('01/01/2100')).toDate();
    }

    cancel(): void {
        this.modalRef.hide();
    }

    checkValidity(value: moment.MomentInput): void {
        this.isValid = moment(value).isBetween(this.minDate, this.maxDate);
        this.date = moment(value).toDate();
    }

    calculateDateValue(offset: 'week' | 'month' | 'year'): Date {
        const value = moment().add(1 as moment.DurationInputArg1, offset as moment.DurationInputArg2);
        return moment(value).toDate();
    }

    setDate(value: Date) {
        this.date = value;
    }

    populateDate(offset: 'week' | 'month' | 'year'): void {
        const value = this.calculateDateValue(offset);
        this.setDate(value);
    }

    addDate(): void {
        this.save.emit({ date: this.date });
        this.modalRef.hide();
    }

}
