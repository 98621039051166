
import {
    Component, OnInit, OnDestroy
} from '@angular/core';
import { StateService } from '@uirouter/core';
import { Subject } from 'rxjs';
import {
    tap, takeUntil, filter,
    last
} from 'rxjs/operators';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import { MESSAGES, ROUTES } from '@app/core/constants';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { Team } from '@app/shared/models';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import template from './log-template-type-selector.component.html';
import styles from './log-template-type-selector.component.scss';
import { LogTemplateType } from './log-template-type-selector.component.types';

@Component({
    selector: 'log-template-type-selector',
    template,
    styles: [String(styles)]
})
export class LogTemplateTypeSelectorComponent implements OnInit, OnDestroy {

    currentTeam: Team;
    private readonly destroy$ = new Subject<void>();

    constructor(
        private currentSessionService: CurrentSessionService,
        private featureFlagService: FeatureFlagService,
        private notificationService: NotificationsService,
        private $state: StateService
    ) { }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit(): void {
        this.currentTeam = this.currentSessionService.getCurrentTeam();
        this.redirectIfFeatureFlagDisabled();
    }

    private getRouteForLogType(logType: string): string {
        switch (logType) {
            case LogTemplateType.ELOG:
                return ROUTES.elogs;
            case LogTemplateType.DOA:
                return ROUTES.doaLogs;
            default: return '';
        }
    }

    private redirectIfFeatureFlagDisabled(): void {
        this.featureFlagService.getFlag(FEATURE_FLAGS.DOA_LOG_TEMPLATES, false)
            .pipe(
                filter((isDoaEnabled) => isDoaEnabled !== undefined),
                tap((isDoaEnabled) => {
                    if (!isDoaEnabled) {
                        this.$state.go(ROUTES.elogs, { logType: LogTemplateType.ELOG });
                    }
                    else if (!this.currentTeam.settings.features.logTemplates) {
                        this.notificationService.error(MESSAGES.premiumFeatureErrorMessage('Log templates'));
                        this.$state.go(ROUTES.binders);
                    }
                }),
                takeUntil(this.destroy$)
            ).subscribe();
    }

    selectLogTemplateType(type: string): void {
        this.$state.go(this.getRouteForLogType(type), { logType: type });
    }
}
