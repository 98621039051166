import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import * as _ from 'lodash';
import { filter, take, takeUntil } from 'rxjs/operators';

import { User, Role, UserRole } from '@app/shared/models';
import {
    SORT, CHECKBOX_STATES, MAX_DATE_ISO, SUBJECT_TYPES, ROLE_BLINDED_STATUSES
} from '@app/core/constants';
import { CurrentSessionService } from '@app/core/current-session.service';
import { TeamService } from '@app/shared/teams/team.service';
import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { FilteredSelectEvent } from '@app/widgets/filtered-select/filtered-select.component';

import { ModalsService } from '@app/shared/modal-helper/modals.service';
import { ConfirmBlindedRolesWarningComponent } from '@app/components/roles/components/confirm-blinded-roles-warning/confirm-blinded-roles-warning.component';
import { FeatureFlagService } from '@app/core/feature-flag.service';
import { FEATURE_FLAGS } from '@app/core/constants/feature-flags';
import { Subject } from 'rxjs';
import { DecoratedUserRole, ManageAccessSubmitEvent, SubmitData } from './manage-access.component.types';

import template from './manage-access.component.html';
import styles from './manage-access.component.scss';

@Component({
    selector: 'manage-access',
    styles: [String(styles)],
    template,
    providers: [{ provide: '$scope', useExisting: '$rootScope' }] // date-time-picker workaround
})
export class ManageAccessComponent implements OnInit {
    @Output() dismiss = new EventEmitter<void>();
    @Output() submit = new EventEmitter<ManageAccessSubmitEvent>();
    @Input() canAssignDates = false;
    @Input() canAssignRoles = false;
    @Input() items: UserRole[] = [];
    @Input() subject: User | Role;

    itemType: string;
    SORT = SORT;
    MAX_DATE = new Date(MAX_DATE_ISO);
    now = new Date();
    bulkMode = false;
    timezone: string;
    canSubmit = false;
    canUndo = false;
    isProcessing = false;
    loadingSearchData = false;
    searchData: (User | Role)[] = [];
    decoratedItems: DecoratedUserRole[];
    originalItems: { [id: string]: UserRole };
    selectedCount = 0;
    notRemovedCount = 0;
    filter: string;
    headerCheckboxState = CHECKBOX_STATES.NOT_SELECTED;
    bulk: {
        isActive?: boolean;
        start?: Date;
        end?: Date;
        isInvalid: boolean;
    } = { isInvalid: false };

    areBlindedUnblindedRolesEnabled = false;
    usersWithConflictingRolesMap: Map<string, User> = new Map();
    conflictingRolesForSingleUser: Role[] = [];
    activePill: typeof ROLE_BLINDED_STATUSES[keyof typeof ROLE_BLINDED_STATUSES] | '' = '';
    private readonly destroy$ = new Subject<void>();

    constructor(
        private Teams: TeamService,
        private CurrentSession: CurrentSessionService,
        private Modals: ModalsService,
        private FeatureFlags: FeatureFlagService
    ) { }

    ngOnInit(): void {
        this.FeatureFlags
            .getFlag(FEATURE_FLAGS.BLINDED_UNBLINDED_ROLES, false)
            .pipe(
                filter((value) => value !== undefined),
                takeUntil(this.destroy$)
            )
            .subscribe((value) => {
                this.areBlindedUnblindedRolesEnabled = value
                    && this.CurrentSession.getCurrentTeam().permissions.manageBlindedUnblindedRoles;
            });

        this.itemType = this.subject.type === SUBJECT_TYPES.ROLE ? SUBJECT_TYPES.USER : SUBJECT_TYPES.ROLE;
        this.decoratedItems = sortByLexicographically<DecoratedUserRole>(
            this.items.map(this.decorateItemWithSubjectData),
            'name'
        );
        this.notRemovedCount = this.decoratedItems.length;
        // check if there is already assigned blindedStatus conflicts
        this.originalItems = this.decoratedItems.reduce((acc, item) => {
            acc[item.id] = { ...item };
            return acc;
        }, {});

        this.timezone = this.CurrentSession.getCurrentTeam().settings.timezone;
        this.searchRolesOrUsers('');
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    get blindedStatuses() {
        return ROLE_BLINDED_STATUSES;
    }

    get subjectTypes() {
        return SUBJECT_TYPES;
    }

    pillClick(clickedBlindedStatus: string) {
        this.activePill = this.activePill === clickedBlindedStatus ? '' : clickedBlindedStatus;
        this.searchRolesOrUsers('');
    }

    searchRolesOrUsers = (query: string): void => {
        this.loadingSearchData = true;
        this.Teams.searchRolesAndUsers(`${this.itemType} ${query}`)
            .pipe(take(1))
            .subscribe((results) => {
                let filteredResults = results;
                if (this.shouldFilterBlindedUnblindedRoles()) {
                    filteredResults = results.filter((res) => (res as Role)?.blindedStatus === this.activePill);
                }
                this.searchData = filteredResults
                    .filter((res) => !this.decoratedItems.find((i) => (i.user || i.role).id === res.id));
                this.loadingSearchData = false;
            });
    }

    shouldFilterBlindedUnblindedRoles(): boolean {
        return this.areBlindedUnblindedRolesEnabled
            && this.itemType === SUBJECT_TYPES.ROLE
            && !!this.activePill;
    }

    toggleSelected(item: DecoratedUserRole): void {
        item.selected = !item.selected;
        item.selected ? this.selectedCount += 1 : this.selectedCount -= 1;
        if (this.bulkMode) {
            this.setIsActive(item, this.bulk.isActive);
            this.setBulkDateToItems();
        }
        this.headerCheckboxState = this.getHeaderCheckboxState();
    }

    toggleSelectAll(): void {
        const allSelected = this.headerCheckboxState === CHECKBOX_STATES.SELECTED;

        this.decoratedItems.forEach((item) => {
            item.selected = item.deleted ? false : !allSelected;
            if (this.bulkMode && item.selected) {
                this.setIsActive(item, this.bulk.isActive);
            }
        });
        this.selectedCount = allSelected ? 0 : this.notRemovedCount;

        if (this.bulkMode) {
            this.setBulkDateToItems();
        }
        this.headerCheckboxState = this.getHeaderCheckboxState();
    }

    toggleBulkAssign(): void {
        this.bulkMode = !this.bulkMode;
        this.bulk.isActive = false;

        if (this.selectedCount !== 0) {
            this.decoratedItems.forEach((item) => item.selected && this.setIsActive(item, this.bulk.isActive));
            this.setBulkDateToItems();
        }
    }

    private getHeaderCheckboxState(): CHECKBOX_STATES {
        if (this.decoratedItems.length) {
            if (this.selectedCount === this.notRemovedCount && this.selectedCount > 0) {
                return CHECKBOX_STATES.SELECTED;
            }

            if (this.selectedCount) {
                return CHECKBOX_STATES.PARTIALLY_SELECTED;
            }
        }

        return CHECKBOX_STATES.NOT_SELECTED;
    }

    checkForChanges(): void {
        const modalData = this.getModalData();
        const numberOfChanges = modalData.creates.length + modalData.updates.length + modalData.deletes.length;
        this.canSubmit = !!numberOfChanges;
    }

    preventSubmit(): void {
        this.canSubmit = false;
    }

    addItem = (event: FilteredSelectEvent<User | Role>): void => {
        const added = event.added[0];
        if (this.bulkMode) {
            return;
        }
        const item = this.decorateItemWithSubjectData({
            isActive: false,
            [this.itemType]: added,
            userId: this.itemType === SUBJECT_TYPES.USER ? added.id : this.subject.id,
            roleId: this.itemType === SUBJECT_TYPES.USER ? this.subject.id : added.id
        });

        this.decoratedItems = [item, ...this.decoratedItems];
        this.notRemovedCount += 1;
        this.checkForChanges();
        this.removeAddedItemFromCurrentSearchResults(item);
    }

    removeItem(item: DecoratedUserRole): void {
        item.deleted = true;
        if (item.selected) {
            item.selected = false;
            this.selectedCount -= 1;
        }
        this.notRemovedCount -= 1;
        this.checkForChanges();
        this.headerCheckboxState = this.getHeaderCheckboxState();
    }

    undoRemoveItem(item: DecoratedUserRole): void {
        item.deleted = false;
        this.notRemovedCount += 1;
        this.checkForChanges();
        this.headerCheckboxState = this.getHeaderCheckboxState();
    }

    setDate(item: DecoratedUserRole, key: 'start' | 'end', event: { value: Date }): void {
        item[key] = event.value ? event.value.toISOString() : null;
        this.checkForChanges();
        item.datesInvalid = this.getIsInvalid(item.start, item.end);
    }

    setDateBulk(key: 'start' | 'end', event: { value: Date }): void {
        this.bulk[key] = event.value;

        if (this.bulkIsValid()) {
            this.setBulkDateToItems();
        }
        this.bulk.isInvalid = this.getIsInvalid(this.bulk.start, this.bulk.end);
    }

    bulkIsValid(): boolean {
        const isOngoing = !this.bulk.start && (!this.bulk.end || this.bulk.end >= new Date());
        const validStart = !this.bulk.start || this.bulk.start >= new Date();
        const validEnd = !this.bulk.end || !this.bulk.start || (this.bulk.end >= this.bulk.start && this.bulk.end >= new Date());
        return !this.bulk.isActive || isOngoing || (validStart && validEnd);
    }

    setBulkDateToItems(): void {
        this.decoratedItems.forEach((item) => {
            if (item.selected) {
                this.setDate(item, 'start', { value: this.bulk.start ? this.bulk.start : null });
                this.setDate(item, 'end', { value: this.bulk.end ? this.bulk.end : null });
            }
        });
    }

    setIsActive(item: DecoratedUserRole, isActive: boolean): void {
        Object.assign(item, { start: '', end: '' });
        item.isActive = isActive;

        const currentTeam = this.CurrentSession.getCurrentTeam();

        if (!this.areBlindedUnblindedRolesEnabled) {
            this.checkForChanges();
            return;
        }

        if (this.subject.type === SUBJECT_TYPES.ROLE) {
            this.Teams.getUserRole(currentTeam.id, item.user.id).subscribe((userRoles) => {
                const conflictedBlindedStatus = this.checkBlindedUnblindedUserRoleConflict(
                    (this.subject as Role)?.blindedStatus,
                    userRoles
                );
                if (conflictedBlindedStatus) {
                    item.isActive
                        ? this.usersWithConflictingRolesMap.set(item.user.id, item.user)
                        : this.usersWithConflictingRolesMap.delete(item.user.id);
                }
            });
        }

        if (this.subject.type === SUBJECT_TYPES.USER) {
            const conflictingBlindedAndUnblindedRoles = this.findConflictingBlindedAndUnblindedRoles(
                this.decoratedItems
            );
            this.conflictingRolesForSingleUser = conflictingBlindedAndUnblindedRoles.map((decoratedItem) => decoratedItem.role);
        }

        this.checkForChanges();
    }

    checkBlindedUnblindedUserRoleConflict(blindedStatus: string, roles: Role[]):boolean {
        const oppositeValue = blindedStatus === ROLE_BLINDED_STATUSES.BLINDED
            ? ROLE_BLINDED_STATUSES.UNBLINDED
            : ROLE_BLINDED_STATUSES.BLINDED;

        return roles.some((item) => item.blindedStatus === oppositeValue);
    }

    findConflictingBlindedAndUnblindedRoles(decorateduserRoles: DecoratedUserRole[]) {
        const activeBlindedAndUnblindedRoles = decorateduserRoles.filter((decoratedUserRole) => {
            return decoratedUserRole.isActive
            && (
                decoratedUserRole.role.blindedStatus === ROLE_BLINDED_STATUSES.BLINDED
                || decoratedUserRole.role.blindedStatus === ROLE_BLINDED_STATUSES.UNBLINDED
            );
        });

        const haveBlindedRoles = activeBlindedAndUnblindedRoles
            .some((role) => role.role.blindedStatus === ROLE_BLINDED_STATUSES.BLINDED);
        const haveUnblindedRoles = activeBlindedAndUnblindedRoles
            .some((role) => role.role.blindedStatus === ROLE_BLINDED_STATUSES.UNBLINDED);

        return haveBlindedRoles && haveUnblindedRoles ? activeBlindedAndUnblindedRoles : [];
    }

    toggleIsActiveBulk(): void {
        this.bulk.isActive = !this.bulk.isActive;
        this.decoratedItems.forEach((item) => item.selected && this.setIsActive(item, this.bulk.isActive));

        if (this.bulkIsValid()) {
            this.setBulkDateToItems();
        }
    }

    private decorateItemWithSubjectData = (userRole: Partial<UserRole>): DecoratedUserRole => {
        let name: string;
        if (userRole.user && userRole.user.name) {
            name = userRole.user.name;
        }
        else {
            name = userRole.role && userRole.role.name;
        }
        return {
            ...userRole,
            selected: false,
            deleted: false,
            name: name || '',
            datesInvalid: false
        };
    }

    private getIsInvalid(start: Date | string | undefined, end: Date | string | undefined): boolean {
        return start && end && new Date(start) >= new Date(end);
    }

    private getUpdateParams(oldAccess: UserRole, newAccess: DecoratedUserRole): SubmitData['updates'][0] | undefined {
        let updated = false;
        const updates: SubmitData['updates'][0] = {
            roleId: newAccess.roleId,
            userId: newAccess.userId,
            isActive: newAccess.isActive
        };

        if (oldAccess.isActive !== newAccess.isActive) {
            updated = true;
        }

        if (new Date(oldAccess.start).getTime() !== new Date(newAccess.start).getTime()) {
            updates.start = newAccess.start || null;
            updated = true;
        }

        if (new Date(oldAccess.end).getTime() !== new Date(newAccess.end || this.MAX_DATE).getTime()) {
            updates.end = newAccess.end || this.MAX_DATE.toISOString();
            updated = true;
        }

        return updated ? updates : undefined;
    }

    private getCreateParams(item: DecoratedUserRole): SubmitData['creates'][0] {
        return {
            userId: item.userId,
            roleId: item.roleId,
            start: (!item.start || _.isNaN(new Date(item.start).getTime())) ? undefined : item.start,
            end: (!item.end || _.isNaN(new Date(item.end).getTime())) ? undefined : item.end,
            ...item.isActive && { isActive: item.isActive }
        };
    }

    private removeAddedItemFromCurrentSearchResults(added: DecoratedUserRole): void {
        const newSearchData = this.searchData.slice();
        const index = this.searchData.findIndex((searchItem) => searchItem.id === added[this.itemType].id);
        newSearchData.splice(index, 1);
        this.searchData = newSearchData;
    }

    getModalData(): SubmitData {
        const creates: SubmitData['creates'] = [];
        const updates: SubmitData['updates'] = [];
        const sames: SubmitData['sames'] = [];
        const deletes: SubmitData['deletes'] = [];
        this.decoratedItems.forEach((item) => {
            const originalItem = this.originalItems[item.id];
            if (!originalItem) {
                if (!item.deleted) {
                    creates.push(this.getCreateParams(item));
                }
                return;
            }

            if (item.deleted) {
                deletes.push({ userId: item.userId, roleId: item.roleId });
                return;
            }

            const update = this.getUpdateParams(originalItem, item);
            if (update) {
                updates.push(update);
                return;
            }

            sames.push(originalItem[this.itemType]);
        });

        return {
            creates,
            updates,
            deletes,
            sames
        };
    }

    handleSubmit(): void {
        if (!this.canSubmit) {
            return;
        }
        this.isProcessing = true;

        if (!this.areBlindedUnblindedRolesEnabled) {
            this.submit.emit({
                data: this.getModalData(),
                onSuccess: () => {
                    this.dismiss.emit();
                    this.isProcessing = false;
                },
                onError: () => {
                    this.isProcessing = false;
                }
            });
            return;
        }

        if (this.subject.type === SUBJECT_TYPES.ROLE) {
            this.confirmConflictedUsers();
        }

        if (this.subject.type === SUBJECT_TYPES.USER) {
            this.confirmConflictedRoles();
        }
    }

    confirmConflictedUsers() {
        const usersWithConflictingRolesArray = Array.from(this.usersWithConflictingRolesMap.values());


        if (!usersWithConflictingRolesArray.length) {
            this.submit.emit({
                data: this.getModalData(),
                onSuccess: () => {
                    this.dismiss.emit();
                    this.isProcessing = false;
                },
                onError: () => {
                    this.isProcessing = false;
                }
            });
            return;
        }

        const confirmRiskModal = this.Modals.show(ConfirmBlindedRolesWarningComponent, {
            animated: false,
            class: 'modal-md',
            initialState: {
                data: usersWithConflictingRolesArray,
                dataType: SUBJECT_TYPES.USER,
                subject: this.subject
            }
        });

        confirmRiskModal.content.confirmEvent.subscribe(() => {
            confirmRiskModal.hide();
            this.submit.emit({
                data: this.getModalData(),
                onSuccess: () => {
                    this.dismiss.emit();
                    this.isProcessing = false;
                },
                onError: () => {
                    this.isProcessing = false;
                }
            });
        });

        confirmRiskModal.content.cancelEvent.subscribe(() => {
            confirmRiskModal.hide();
            this.dismiss.emit();
            this.isProcessing = false;
        });
    }

    confirmConflictedRoles() {
        if (!this.conflictingRolesForSingleUser.length) {
            this.submit.emit({
                data: this.getModalData(),
                onSuccess: () => {
                    this.dismiss.emit();
                    this.isProcessing = false;
                },
                onError: () => {
                    this.isProcessing = false;
                }
            });
            return;
        }

        const confirmRiskModal = this.Modals.show(ConfirmBlindedRolesWarningComponent, {
            animated: false,
            class: 'modal-md',
            initialState: {
                data: this.conflictingRolesForSingleUser,
                dataType: SUBJECT_TYPES.ROLE,
                subject: this.subject
            }
        });

        confirmRiskModal.content.confirmEvent.subscribe(() => {
            confirmRiskModal.hide();
            this.submit.emit({
                data: this.getModalData(),
                onSuccess: () => {
                    this.dismiss.emit();
                    this.isProcessing = false;
                },
                onError: () => {
                    this.isProcessing = false;
                }
            });
        });

        confirmRiskModal.content.cancelEvent.subscribe(() => {
            confirmRiskModal.hide();
            this.dismiss.emit();
            this.isProcessing = false;
        });
    }

    cancel(): void {
        if (this.isProcessing) {
            return;
        }
        this.dismiss.emit();
    }

    public updateFilter(text: string): void {
        this.filter = text;
    }
}
